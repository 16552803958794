import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: "https://api.aaruush.org/api/v1",
  // baseURL: "http://localhost:4000/api/v1",
  headers: {
    Authorization: Cookies.get("access_token") || null,
  },
});

export default instance;
