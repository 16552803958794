// import React from "react";

// const Form = ({ formData, setFormData }) => {

//   return (
//     <div>
//       Form
//       <div></div>
//     </div>
//   );
// };

// export default Form;

import React, { useEffect, useState } from "react";

const DynamicFormBuilder = ({ formData, setFormData }) => {
  const [formFields, setFormFields] = useState([]);
  const [fieldType, setFieldType] = useState("");
  const [fieldDetails, setFieldDetails] = useState({});

  const handleFieldTypeChange = (e) => {
    setFieldType(e.target.value);
    setFieldDetails({});
  };

  useEffect(() => {
    setFormData({ ...formData, dynamicform: formFields });
  }, [formFields]);

  const handleFieldDetailChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, e.target);
    if (name != "required") {
      setFieldDetails((prevFieldDetails) => ({
        ...prevFieldDetails,
        [name]: value,
      }));
    } else {
      setFieldDetails((prevFieldDetails) => ({
        ...prevFieldDetails,
        [name]: e.target.checked,
      }));
    }
  };

  const handleAddField = (e) => {
    e.preventDefault();
    if (fieldDetails.label === undefined || fieldDetails.label === "") {
      window.alert("Fill");
    } else {
      setFormFields((prevFormFields) => [
        ...prevFormFields,
        { ...fieldDetails, type: fieldType },
      ]);
      setFieldDetails({});
    }
  };

  const renderFieldDetailsForm = () => {
    switch (fieldType) {
      case "text":
      case "email":
      case "tel":
      case "date":
      case "time":
      case "datetime":
      case "number":
        return (
          <div>
            <div className="form-group">
              <label htmlFor="label">Label:</label>
              <input
                type="text"
                className="form-control"
                id="label"
                name="label"
                value={fieldDetails.label || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="placeholder">Placeholder:</label>
              <input
                type="text"
                className="form-control"
                id="placeholder"
                name="placeholder"
                value={fieldDetails.placeholder || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="placeholder">Section:</label>
              <input
                type="text"
                className="form-control"
                id="section"
                name="section"
                value={fieldDetails.section || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="required">Required:</label>
              <input
                type="checkbox"
                className="form-check-input"
                id="required"
                name="required"
                checked={fieldDetails.required || false}
                onChange={handleFieldDetailChange}
              />
            </div>
          </div>
        );
      case "select":
        return (
          <div>
            <div className="form-group">
              <label htmlFor="label">Label:</label>
              <input
                type="text"
                className="form-control"
                id="label"
                name="label"
                value={fieldDetails.label || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="options">Options (comma-separated):</label>
              <input
                type="text"
                className="form-control"
                id="options"
                name="options"
                value={fieldDetails.options || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="placeholder">Section:</label>
              <input
                type="text"
                className="form-control"
                id="section"
                name="section"
                value={fieldDetails.section || ""}
                onChange={handleFieldDetailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="required">Required:</label>
              <input
                type="checkbox"
                className="form-check-input"
                id="required"
                name="required"
                checked={fieldDetails.required || false}
                onChange={handleFieldDetailChange}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderFormFields = () => {
    return formFields.map((field, index) => {
      return (
        <div key={index}>
          <label>{field.label}</label>
          {field.type === "select" ? (
            <select className="form-control">
              {field.options.split(",").map((option, idx) => (
                <option key={idx} value={option.trim()}>
                  {option.trim()}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={field.type}
              className="form-control"
              placeholder={field.placeholder}
              required={field.required}
            />
          )}
        </div>
      );
    });
  };

  return (
    <div className="border p-2">
      <h2>Create Your Form</h2>
      <div className="form-group">
        <label htmlFor="fieldType">Select Field Type:</label>
        <select
          className="form-control"
          id="fieldType"
          value={fieldType}
          onChange={handleFieldTypeChange}
        >
          <option value="">Select a Field Type</option>
          <option value="text">Text</option>
          <option value="email">Email</option>
          <option value="tel">Telephone</option>
          <option value="date">Date</option>
          <option value="time">Time</option>
          <option value="datetime">DateTime</option>
          <option value="number">Number</option>
          <option value="select">Select (Dropdown)</option>
        </select>
      </div>
      {renderFieldDetailsForm()}
      <button
        className="btn btn-primary"
        onClick={handleAddField}
        disabled={!fieldType}
      >
        Add Field
      </button>

      <h3>Your Form:</h3>
      {formFields.length > 0 ? (
        //   renderFormFields()

        <pre>
          {/* {formFields.map((field, index) => {
            return (
              <div key={index}>
                <label>{field.label}</label>
                {field.type === "select" ? (
                  <select className="form-control">
                    {field.options.split(",").map((option, idx) => (
                      <option key={idx} value={option.trim()}>
                        {option.trim()}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    placeholder={field.placeholder}
                    required={field.required}
                  />
                )}
              </div>
            );
          })} */}
          {/* display formdata as json */}
          {JSON.stringify(formFields, null, 2)}
        </pre>
      ) : (
        <p>No fields added yet.</p>
      )}
    </div>
  );
};

export default DynamicFormBuilder;
