import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Timeline = ({ accessToken }) => {
  const [formData, setFormData] = useState({});
  // const [checked, setChecked] = useState(false);
  const [timeline, settimeline] = useState([]);
  // const [Timeline, setTimeline] = useState();
  // const [singletimeline, setSingleTimeline] = useState([]);

  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    async function gettimeline() {
      const data = (await API.get("/static/patandacc")).data;
      //   console.log(data);
      settimeline(data);
      //   console.log(data);
    }
    gettimeline();
  }, [change]);

  // edit timeline
  const edit_timeline = async (data) => {
    setEdit(true);
    setFormData(data);
    // setChecked(data.thisYear);
  };

  // const getSingleTimeline = async (data) => {
  //   const single = (await API.get(`/static/patandacc/${singletimeline}`)).data;
  //   setTimeline(single);
  // };
  const update_timeline = async () => {
    const message = (
      await API.put(`/static/patandacc/${formData.id}`, {
        ...formData,
      })
    ).data.message;
    setEdit(false);
    setFormData({});
    setChange(!change);
    return message;
  };

  // delete timeline
  const delete_timeline = async (id) => {
    const message = (await API.delete(`/static/patandacc/${id}`)).data;
    console.log(message);
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const message = edit
      ? update_timeline()
      : (
          await API.post("/static/patandacc", {
            ...formData,
          })
        ).data.message;
    alert(message);
    setChange(!change);
  };
  return (
    <div className="container">
      <div className="row">
        {/* event form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="fw-bold mx-auto">Post a Pat and Acc</div>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="category"
                value={formData.category}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={formData.image}
                onChange={handleInput}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show all timeline  */}
        <div
          className="col-lg-6 card mt-1 mb-1 p-1"
          style={{ maxHeight: "400px", overflowY: "scroll" }}
        >
          <div className="fw-bold mx-auto">Get All Pat and Acc</div>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {timeline &&
              timeline
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((data, idx) => (
                  <div key={idx} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${idx}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${idx}`}
                      >
                        {data.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${idx}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(data).map((key, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {data[key].toString()}
                            </div>
                          );
                        })}

                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_timeline(data)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_timeline(data.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
