import React, { useState, useEffect } from "react";
import API from "../Services/axios";
import CsvDownloadButton from "react-json-to-csv";
// import XLSX from "xlsx";

// const downloadExcel = (data, type) => {
//   if (data) {
//     const worksheet = XLSX.utils.json_to_sheet(data);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//     //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
//     //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
//     XLSX.writeFile(workbook, `CFA_${type}_${new Date().getTime()}.xlsx`);
//   }
// };

const CFA = () => {
  const [cfas, setCFAs] = useState([]);
  const [envisionCfas, setEnvisionCfas] = useState([]);
  useEffect(() => {
    async function getCFAs() {
      const data = (await API.get("/cfa/users")).data;
      var finData = [];
      var envision = [];
      for (let i = 0; i < data.length; i++) {
        if (!data[i].expertise1) {
          finData.push(data[i]);
        } else {
          envision.push(data[i]);
        }
      }
      // const envisionData = (await API.get("/cfa/users/envision")).data;
      setEnvisionCfas(envision);
      setCFAs(finData);
    }
    getCFAs();
  }, []);
  return (
    <>
      {/* show cfas  */}
      <div className="col-lg-7">
        {/* <div className="accordion accordion-flush" id="accordionFlushExample">
          {cfas &&
            cfas.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((cfa) => (
              <div key={cfa.id} className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${cfa.id}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${cfa.id}`}
                  >
                    {cfa.name}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${cfa.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {Object.keys(cfa).map((key, index) => {
                      if (key === "image") {
                        return "";
                      }
                      return (
                        <div className="d-flex" key={index}>
                          <span className="mx-2">{key} :</span>
                          {cfa[key].toString()}
                        </div>
                      );
                    })}

                    <div className="d-flex">
                      <span className="mx-2">Image :</span>
                      <a
                        href={cfa.image}
                        rel="noopener noreferrer"
                        target="_blank"
                        
                      >
                        {" "}
                        View Image
                      </a>
                    </div>
                    
                  </div>
                </div>
              </div>
            ))}
        </div> */}
        {/* <JsonToExcel
          title="Download CFA Data as Excel"
          data={cfas}
          filename={`CFA_${new Date().getTime()}.csv`}
          btnClassName="custom-classname"
        />

        <JsonToExcel
          title="Download Envision CFA Data as Excel"
          data={envisionCfas}
          filename={`CFA_ENVISION_${new Date().getTime()}.csv`}
          btnClassName="custom-classname"
        /> */}
        <CsvDownloadButton
          data={cfas}
          filename={`CFA_${new Date().getTime()}.csv`}
          delimiter={","}
          headers={[
            "phoneNumber",
            "regNumber",
            "timestamp",
            "email",
            "name",
            "branch",
            "year",
            "preference1",
            "ids",
            "preference2",
            "whatsappNumber",
            "aaruush",
            "srmEmail",
          ]}
        >
          <button
            onClick={() => {
              // downloadExcel(cfas, "Aaruush");
            }}
          >
            Download CFA Data
          </button>
        </CsvDownloadButton>
        <CsvDownloadButton
          data={envisionCfas}
          filename={`/CFA_ENVISION_${new Date().getTime()}.csv`}
          delimiter={","}
          headers={[
            "phoneNumber",
            "portfolio",
            "github",
            "expertise2",
            "regNumber",
            "expertise1",
            "linkedIn",
            "timestamp",
            "other",
            "email",
            "name",
            "branch",
            "year",
            "preference1",
            "ids",
            "preference2",
            "whatsappNumber",
            "aaruush",
            "srmEmail",
          ]}
        >
          <button>Download Envision CFA Data</button>
        </CsvDownloadButton>
      </div>
    </>
  );
};

export default CFA;
