import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Gallery = ({ accessToken }) => {
  const [formData, setFormData] = useState({});
  const [imageArr, setImageArr] = useState([]);
  const [imageurl, setImageurl] = useState("");
  const [gallery, setAllGallery] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    async function getAllGallery() {
      const data = (await API.get("/gallery")).data;
      setAllGallery(data);
    }
    getAllGallery();
  }, [change]);

  // edit gallery
  const edit_gallery = async (gallery) => {
    setEdit(true);
    setFormData(gallery);
    setImageArr(gallery.image);
  };

  const update_gallery = async () => {
    const message = (
      await API.put(`/gallery/${formData.id}`, {
        name: formData.name,
        category: formData.category,
        edition: formData.edition,
        image: imageArr,
      })
    ).data.message;
    setEdit(false);
    setFormData({});
    setImageArr([]);
    setChange(!change);
    return message;
  };

  // delete gallery
  const delete_gallery = async (id) => {
    const message = (await API.delete(`/gallery/${id}`)).data;
    alert(message);
    setChange(!change);
    setImageArr([]);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (imageArr.length) {
      const message = edit
        ? update_gallery()
        : (
            await API.post("/gallery", {
              ...formData,
              image: imageArr,
            })
          ).data.message;
      alert(message);
      setFormData({});
      setImageArr([]);
      setChange(!change);
    } else {
      alert("Please Fill all the required fields");
    }
  };

  return (
    <div className="container">
      <div className="row">
        {/* gallery form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="category"
                value={formData.category}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Date</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="date"
                value={formData.date}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="description"
                value={formData.description}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Edition</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="edition"
                value={formData.edition}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={imageurl}
                onChange={(e) => {
                  setImageurl(e.target.value);
                }}
              />
              <span
                className="btn cursor-pointer bg-success text-white mt-2"
                onClick={() => {
                  setImageArr([...imageArr, imageurl]);
                  setImageurl("");
                }}
              >
                Add Image to Array
              </span>
              <span
                className="btn cursor-pointer bg-success text-white mt-2 mx-2"
                onClick={() => {
                  setImageArr([]);
                }}
              >
                Remove all Images from the Array
              </span>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>

        {/* show gallery  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {gallery &&
              gallery
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((gallery, idx) => (
                  <div key={idx} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${idx}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${idx}`}
                      >
                        {gallery.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${idx}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(gallery).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {gallery[key].toString()}
                            </div>
                          );
                        })}
                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <div className="d-flex flex-col">
                            {gallery.image.map((data, idx) => {
                              return (
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={data}
                                  alt={idx}
                                  className="mx-2"
                                >
                                  Image {idx + 1}
                                </a>
                              );
                            })}
                          </div>
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_gallery(gallery)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_gallery(gallery.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
