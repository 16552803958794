import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";
import CsvDownloadButton from "react-json-to-csv";
import exportFromJSON from "export-from-json";
import { CSVLink, CSVDownload } from "react-csv";

const Events_User = ({ accessToken }) => {
  const [fileName, setFileName] = useState("users");
  const [users, setUsers] = useState([]);
  const [id, setId] = useState();
  const [category, setCategory] = useState();

  const [singlecategory, setSingleCategory] = useState();
  const [singleid, setSingleID] = useState();
  const [email, setEmail] = useState();
  const [singleuser, setSingleUser] = useState();
  const [headers, setHeaders] = useState([]);
  const [eventscategory, setEventsCategory] = useState([]);
  const [attendedUsers, setAttendedUsers] = useState([]);
  // const [change, setChange] = useState(false);
  // useEffect
  useEffect(() => {
    async function getEventsCategory() {
      const data = (await API.get("/static/EventCategory")).data;
      setEventsCategory(data);
    }
    getEventsCategory();
  });

  // const downloadUsers = () => {
  //   const data = users;
  // };
  const ExportToExcel = () => {};
  const fileType = "xlsx";
  // exportFromJSON({ users, fileName, fileType });
  const getUsers = async (e) => {
    console.log(category, id);
    if (category && id) {
      e.preventDefault();
      setUsers([]);
      setFileName(`AARUUSH_USERS_${id}_${new Date().getTime()}`);
      const data = (await API.get(`/events/${category}/${id}/users`)).data;
      console.log(data);
      const head = Object.keys(data[0]);
      head.pop("event");
      setHeaders(head);
      setUsers(data);
    } else {
      alert("Please Fill all required fields");
    }
  };
  const getAttendedUsers = async (e) => {
    console.log(category, id);
    if (category && id) {
      e.preventDefault();
      setUsers([]);
      setFileName(`AARUUSH_ATTENDED_USERS_${id}_${new Date().getTime()}`);
      const data = (await API.patch(`/events/${category}/${id}/users`)).data;
      console.log(data);
      const head = Object.keys(data[0]);
      head.pop("event");
      setHeaders(head);
      setAttendedUsers(data);
    } else {
      alert("Please Fill all required fields");
    }
  };
  const getSingleUser = async (e) => {
    if (singlecategory && singleid && email) {
      e.preventDefault();
      const data = (
        await API.get(`/events/${singlecategory}/${singleid}/users/${email}`)
      ).data;
      setSingleUser(data);
    } else {
      alert("Please Fill all required fields");
    }
  };

  return (
    <>
      {/* show single event */}
      <div
        className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Users of Single Event</div>
        <div className="input-group mb-3">
          <div class="row">
            <div class="col">
              <select
                className="form-select"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Category
                </option>
                {eventscategory
                  .sort(function(a, b) {
                    return a.timestamp - b.timestamp;
                  })
                  .reverse()
                  .map((category, index) => {
                    return (
                      <option
                        key={index}
                        value={category.name}
                        name="category"
                        className="form-control"
                      >
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div class="col">
              <input
                type="text"
                id="categorysingeinput"
                className="form-control"
                placeholder="Event ID"
                aria-label="Event ID"
                aria-describedby="button-addon2"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                onClick={getUsers}
              >
                Button
              </button>
              {users.length > 1 && (
                // <CsvDownloadButton
                //   data={users}
                //   filename={`/AARUUSH_${id}_${new Date().getTime()}.csv`}
                //   delimiter={","}
                //   headers={headers}
                // >
                //   <button>Download</button>
                // </CsvDownloadButton>
                <CSVLink
                  data={users}
                  filename={`/AARUUSH_${id}_${new Date().getTime()}.csv`}
                  target="_blank"
                  className="btn btn-outline-primary"
                >
                  Download
                </CSVLink>
              )}
            </div>
          </div>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {users &&
            users
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((event, idx) => (
                <div key={idx} className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingOnelivecategory"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapselivecategory${idx}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapselivecategory${idx}`}
                    >
                      {event.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapselivecategory${idx}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOnelive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(event).map((key, index) => {
                        if (key === "image" || key === "avatar") {
                          return "";
                        }
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {String(event[key])}
                          </div>
                        );
                      })}
                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.image}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>
                      {/* <div className="d-flex">
                      <button
                        className="btn btn-sm btn-warning"
                        onClick={() => edit_events(event)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger mx-2"
                        onClick={() => delete_events(idx)}
                      >
                        Delete
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div
        className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">
          Get Attended Users of Single Event
        </div>
        <div className="input-group mb-3">
          <div class="row">
            <div class="col">
              <select
                className="form-select"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Category
                </option>
                {eventscategory
                  .sort(function(a, b) {
                    return a.timestamp - b.timestamp;
                  })
                  .reverse()
                  .map((category, index) => {
                    return (
                      <option
                        key={index}
                        value={category.name}
                        name="category"
                        className="form-control"
                      >
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div class="col">
              <input
                type="text"
                id="categorysingeinput"
                className="form-control"
                placeholder="Event ID"
                aria-label="Event ID"
                aria-describedby="button-addon2"
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                onClick={getAttendedUsers}
              >
                Button
              </button>
              {attendedUsers.length > 1 && (
                // <CsvDownloadButton
                //   data={users}
                //   filename={`/AARUUSH_${id}_${new Date().getTime()}.csv`}
                //   delimiter={","}
                //   headers={headers}
                // >
                //   <button>Download</button>
                // </CsvDownloadButton>
                <CSVLink
                  data={attendedUsers}
                  filename={`/AARUUSH_${id}_${new Date().getTime()}.csv`}
                  target="_blank"
                  className="btn btn-outline-primary"
                >
                  Download
                </CSVLink>
              )}
            </div>
          </div>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {attendedUsers &&
            attendedUsers
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((event, idx) => (
                <div key={idx} className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingOnelivecategory"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapselivecategory${idx}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapselivecategory${idx}`}
                    >
                      {event.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapselivecategory${idx}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOnelive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(event).map((key, index) => {
                        if (key === "image" || key === "avatar") {
                          return "";
                        }
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {String(event[key])}
                          </div>
                        );
                      })}
                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.image}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>
                      {/* <div className="d-flex">
                      <button
                        className="btn btn-sm btn-warning"
                        onClick={() => edit_events(event)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger mx-2"
                        onClick={() => delete_events(idx)}
                      >
                        Delete
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div
        className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Single User by Event</div>
        <div className="input-group mb-3">
          <div class="row">
            <div class="col">
              <select
                className="form-select"
                onChange={(e) => {
                  setSingleCategory(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Category
                </option>
                {eventscategory.map((category, index) => {
                  return (
                    <option
                      key={index}
                      value={category.name}
                      name="category"
                      className="form-control"
                    >
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div class="col">
              <input
                type="text"
                id="categorysingeinput"
                className="form-control"
                placeholder="Event ID"
                aria-label="Event ID"
                aria-describedby="button-addon2"
                value={singleid}
                onChange={(e) => {
                  setSingleID(e.target.value);
                }}
              />
            </div>
            <div class="col">
              <input
                type="text"
                id="categorysingeinput"
                className="form-control"
                placeholder="User Email"
                aria-label="User Email"
                aria-describedby="button-addon2"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                onClick={getSingleUser}
              >
                Button
              </button>
            </div>
          </div>
        </div>

        {singleuser && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2
                className="accordion-header"
                id="flush-headingOnelivecategory"
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapselivecategory`}
                  aria-expanded="false"
                  aria-controls={`flush-collapselivecategory`}
                >
                  {singleuser.name}
                </button>
              </h2>
              <div
                id={`flush-collapselivecategory`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOnelive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  {Object.keys(singleuser).map((key, index) => {
                    if (key === "image") {
                      return "";
                    }
                    return (
                      <div className="d-flex" key={index}>
                        <span className="mx-2">{key} :</span>
                        {singleuser[key].toString()}
                      </div>
                    );
                  })}

                  <div className="d-flex">
                    <span className="mx-2">Image :</span>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={singleuser.image}
                      alt={"sponsor"}
                      className="mx-2"
                    >
                      View Image
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Events_User;
