import React from "react";
import DynamicForm from "../DynamicForm";

export default function EventsComp({
  onSubmit,
  formData,
  handleInput,
  Select,
  animatedComponents,
  categoryoptions,
  handleEventCategoryChange,
  options,
  setFormData,
  CreatableSelect,
  regdoptions,
  handlePaymentType,
  checked,
  setChecked,
  setNewField,
  addNewField,
  newfield,
  events,
  edit_events,
  delete_events,
  imageArr,
  setImageArr,
  imageurl,
  setImageurl,
  dynamicForm,
  setDynamicForm,
}) {
  console.log(formData);
  return (
    <>
      <div className="mx-auto fs-3 fw-bold text-center mb-5">EVENTS</div>
      <div className="container col-lg-5">
        <form onSubmit={onSubmit} id="eventform">
          <div className="fw-bold mx-auto">Post a Event</div>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              value={formData.name}
              name="name"
              id="name"
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              value={formData.id}
              name="id"
              id="id"
              placeholder="Enter Unique ID"
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Edition</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              value={formData.edition}
              name="edition"
              id="edition"
              placeholder="Enter Edition - (a24, a25, etc.)"
              onChange={handleInput}
            />
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Category</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={[
                {
                  value: formData.category,
                  label: formData.category,
                },
              ]}
              options={categoryoptions}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  category: e.value,
                });
              }}
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label">
              Sort Category (Hackathons, Workshops, etc.)
            </label>
            <select
              className="form-control"
              name="sortCategory"
              value={formData.sortCategory}
              onChange={handleInput}
            >
              <option value="">Select Category</option>
              <option value="domain-events">domain-events</option>
              <option value="championships">championships</option>
              <option value="workshops">workshops</option>
              <option value="CR">CR</option>
              <option value="Hackathons">Hackathons</option>
              <option value="initiatives">initiatives</option>
              <option value="panel-discussions">panel-discussions</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Sponsors</label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              value={formData.sponsors}
              options={options}
              onChange={(e) => {
                setFormData({ ...formData, sponsors: e });
              }}
            />
          </div>
          <div className="mb-3">
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={dynamicForm}
                onChange={() => setDynamicForm(!dynamicForm)}
              />
              <label className="form-check-label">Dynamic Form</label>
            </div>
            {dynamicForm && (
              <>
                <div className="mb-3">
                  <label className="form-label">Form Sections</label>
                  <textarea
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    value={formData.formSections}
                    name="formSections"
                    id="formmSections"
                    onChange={handleInput}
                  />
                </div>
                <DynamicForm formData={formData} setFormData={setFormData} />
              </>
            )}
            {/* <label className="form-label">Registration Form Fields</label>
            <CreatableSelect
              closeMenuOnSelect={false}
              isMulti
              components={animatedComponents}
              options={regdoptions}
              defaultValue={[
                regdoptions[0],
                regdoptions[1],
                regdoptions[2],
                regdoptions[3],
              ]}
              value={formData.reqdfields}
              onChange={(e) => {
                console.log(e);
                setFormData({ ...formData, reqdfields: e });
              }}
            /> */}
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={formData.outreach}
              onChange={() =>
                setFormData({
                  ...formData,
                  outreach: !formData.outreach,
                })
              }
            />
            <label className="form-check-label">Outreach</label>
          </div>
          {formData.outreach && (
            <div className="mb-3">
              <label className="form-label">Outreach College Name</label>
              <textarea
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="outreachCollege"
                id="outreachCollege"
                value={formData.outreachCollege}
                onChange={handleInput}
              />
            </div>
          )}
          <div className="mb-3">
            <label className="form-label">Date</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="date"
              id="date"
              value={formData.date}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Start Time</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="time"
              id="time"
              value={formData.time}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Start Date</label>
            {/* <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="startdate"
              id="date"
              value={formData.startdate}
              onChange={handleInput}
            /> */}
            <input
              type="date"
              name="startdate"
              className="form-control"
              aria-describedby="emailHelp"
              id="startdate"
              value={formData.startdate ? formData.startdate : ""}
              onChange={handleInput}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label">Location</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="location"
              id="location"
              value={formData.location}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Location Lat</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="locationLat"
              id="locationLat"
              value={formData.locationLat}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Location Lng</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="locationLng"
              id="locationLng"
              value={formData.locationLng}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Mode</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="mode"
              id="mode"
              value={formData.mode}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Registration Link</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="reglink"
              id="reglink"
              value={formData.reglink}
              onChange={handleInput}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Payment Type</label>
            <Select
              closeMenuOnSelect={true}
              components={animatedComponents}
              value={[
                {
                  value: formData.payment_type,
                  label: formData.payment_type,
                },
              ]}
              options={[
                { value: "free", label: "free" },
                { value: "paid", label: "paid" },
              ]}
              onChange={handlePaymentType}
            />
            {/* {console.log(formData.payment_type)} */}
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={formData.cr || false}
              onChange={() => setFormData({ ...formData, cr: !formData.cr })}
            />
            <label className="form-check-label">CR Required?</label>
          </div>
          {/* {console.log(formData.payment_type)} */}
          {formData.payment_type && formData.payment_type === "paid" ? (
            <div className="mb-3">
              <label className="form-label">Amount</label>
              <textarea
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="amount"
                id="amount"
                value={formData.amount}
                onChange={handleInput}
              />
            </div>
          ) : null}

          <div className="mb-3">
            <label className="form-label">Contacts</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="contact"
              id="contact"
              value={formData.contact}
              onChange={handleInput}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">one line Description</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="oneliner"
              value={formData.oneliner}
              onChange={handleInput}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">About the event ( paragraph ) </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="about"
              value={formData.about}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Structure of the event ( paragraph ){" "}
            </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="structure"
              value={formData.structure}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Timeline of the event </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="timeline"
              value={formData.timeline}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Rules of the event </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="rules"
              value={formData.rules}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">FAQ of the event </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="faq"
              value={formData.faq}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Thumbnail Image</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="image"
              value={formData.image}
              onChange={handleInput}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Header Image</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="headerImage"
              value={formData.headerImage}
              onChange={handleInput}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">Price Pool of the event </label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="pricepool"
              value={formData.pricepool}
              onChange={handleInput}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label">Image URL</label>
            <textarea
              type="text"
              className="form-control"
              aria-describedby="emailHelp"
              name="gallery"
              value={imageurl}
              onChange={(e) => {
                setImageurl(e.target.value);
              }}
            />
            <span
              className="btn cursor-pointer bg-success text-white mt-2"
              onClick={() => {
                setImageArr([...imageArr, imageurl]);
                setImageurl("");
              }}
            >
              Add Image to Array
            </span>
            <span
              className="btn cursor-pointer bg-success text-white mt-2 mx-2"
              onClick={() => {
                setImageArr([]);
              }}
            >
              Remove all Images from the Array
            </span>
          </div>

          <div id="newblock"></div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label className="form-check-label">Live</label>
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <hr />
          <div className="input-group mb-3">
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter the field name"
              onChange={(e) => {
                setNewField(e.target.value);
              }}
            />
            <span
              className="input-group-text btn btn-info"
              id="basic-addon2"
              onClick={(e) => {
                if (newfield) {
                  addNewField(newfield);
                } else {
                  alert("Enter the field name");
                }
              }}
            >
              Add New Text Field
            </span>
          </div>
        </form>
      </div>
      {/* show all events  */}
      <div
        className="col-lg-6 card mt-1 mb-1 p-1"
        style={{ maxHeight: "140vh", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get All Events</div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {events &&
            events
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((event, idx) => (
                <div key={idx} className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${idx}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${idx}`}
                    >
                      {event.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${idx}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(event).map((key, index) => {
                        if (key === "image") {
                          return "";
                        } else if (key === "sponsors") {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {event[key].map((sponsor, index) => {
                                return (
                                  <span className="" key={index}>
                                    {sponsor.label},
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else if (key === "sponsors") {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {event[key].map((sponsor, index) => {
                                return (
                                  <span className="" key={index}>
                                    {sponsor.label},
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {JSON.stringify(event[key], null, 2)}
                            </div>
                          );
                        }
                      })}
                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.image}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>

                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_events(event)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() =>
                            delete_events(event.id, event.category)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
}
