import React, { useState } from "react";
import API from "../Services/axios";
// import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
const Navbar = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  // const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = (await API.post("/adminLogin", { email, password })).data;
    console.log(data);
    Cookies.set("access_token", data.accessToken, { expires: 5 });
    Cookies.set("role", data.role, { expires: 5 });
    // localStorage.setItem("access_token", data.accessToken);
    // localStorage.setItem("role", data.role);
    if (data.accessToken) {
      // window.history.push('/')
      // history.push("/");
      window.location.replace("/");
    }
  };

  return (
    <div className="container col-lg-5">
      <form onSubmit={handleSubmit}>
        <h1 class="h3 mb-3 fw-normal">Sign in</h1>
        <div class="form-floating">
          <input
            type="email"
            class="form-control"
            id="floatingInput"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <label for="floatingPassword">Password</label>
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit">
          Sign in
        </button>
      </form>
    </div>
  );
};

export default Navbar;
