// import React from "react";

import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
// import { IconButton } from "@material-ui/core";
// import { CameraAlt } from "@material-ui/icons";
// import "tailwindcss/tailwind.css";
// import axios from "axios";
import { AiFillCamera } from "react-icons/ai";
import API from "../Services/axios";

const QrReaderApp = ({ accessToken }) => {
  const [data, setData] = useState("No result");
  const [readData, setReadData] = useState(false);
  const [userData, setUserData] = useState({});
  const [jsonData, setJsonData] = useState({});

  const handleSubmit = async () => {
    console.log("submit");
    const data = await API.put(
      `events/events/${jsonData.eventId}/users/${jsonData.emailid}/attend`
    )
      .then((res) => {
        window.alert(res.data.message);
        setUserData({ ...userData, attended: true });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(data);
  useEffect(() => {
    if (data !== "No result") {
      const newData = data.split(",").map((item) => {
        return item.trim().split(":");
      });
      const newJsonData = {};
      newData.forEach((item) => {
        newJsonData[item[0]] = item[1];
      });
      setJsonData(newJsonData);
      console.log(newJsonData);
      // const newTicketData = {
    }
  }, [data]);

  useEffect(() => {
    if (jsonData) {
      const fetchData = async () => {
        const data = await API.get(
          `/events/events/${jsonData.eventId}/users/${jsonData.emailid}`
        );
        setUserData(data.data);
      };
      fetchData();
    }
  }, [jsonData]);

  console.log(userData);
  return (
    <div className="container mx-auto">
      <h1>Add attendance for Event</h1>
      {readData ? (
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
              setData(result.text);
              //   console.log(result);
              setReadData(false);
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: "100%" }}
        />
      ) : null}
      <div
        onClick={() => {
          setReadData(true);
        }}
        className="mx-auto"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-evenly",
          width: "max-content",
          background: "#1a202c",
          color: "white",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
          marginTop: "10px",
        }}
      >
        Open QR Scanner
        <AiFillCamera />
      </div>
      {userData && (
        <div className="mx-auto mt-10">
          <div className="text-2xl font-bold">User Details</div>
          <div className="text-xl font-bold">Name: {userData.name}</div>
          <div>Registration Number : {userData["Registration Number"]}</div>
          <div>Email: {userData.email}</div>
          <div>Phone: {userData["Contact number"]}</div>
          <div>College: {userData.college}</div>
          <div>Year: {userData["Year(1/2/3/4)"]}</div>
          <div>Event Name: {userData.event && userData.event.name}</div>
          <div>Event Id: {userData.event && userData.event.id}</div>
          <div>Event Date: {userData.event && userData.event.date}</div>
          <div>Event Time: {userData.event && userData.event.time}</div>

          <div>Attended: {userData.attended ? "Yes" : "No"}</div>

          {/* {JSON.stringify(userData)} */}
          {userData.attended ? (
            <div
              className=""
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                color: "white",
                alignItems: "center",
                background: "#1a202c",
              }}
            >
              Already Attended
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                color: "white",
                alignItems: "center",
                background: "#013220",
              }}
            >
              Mark as Attended
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default QrReaderApp;
