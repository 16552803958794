import React, { useState, useEffect } from 'react';
import API from "../Services/axios"

const SESTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showModal, setShowModal] = useState([false, '']);

  useEffect(() => {
    fetchTemplates();
  }, []);


  const fetchTemplates = async () => {
    try {
      const response = (await API.get('/listtemplates')).data;
      console.log(response)
      if(response.data.res.TemplatesMetadata)
      setTemplates(response.data.res.TemplatesMetadata)
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handlePreview = async (templateName, type) => {
    try {
      const response = (await API.get(`/getTemplate/${templateName}`)).data;
      console.log(response.data.res);
      setSelectedTemplate(response.data.res.Template);
      setShowModal([true, type]);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  };

  const handleDelete = async () => {
    if (selectedTemplate) {
      try {
        await API.delete(`/deleteTemplate/${selectedTemplate.TemplateName}`);
        setShowModal(false);
        fetchTemplates(); // Refresh the template list
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">SES Templates</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <tr key={template.Name}>
              <td>{template.Name}</td>
              <td>{new Date(template.CreatedTimestamp).toLocaleString()}</td>
              <td>
                <button
                  className="btn btn-primary mr-2"
                  onClick={() => handlePreview(template.Name, 'view')}
                >
                  Preview
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handlePreview(template.Name, 'delete')}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal[0] && selectedTemplate && (
        <div className="modal fade show" style={{ display: 'block' }} tabindex="-1" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{selectedTemplate.TemplateName} Preview</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Subject:</h6>
                <p>{selectedTemplate.SubjectPart}</p>
                <h6>HTML Content:</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: selectedTemplate.HtmlPart }}
                />
              </div>
              <div className="modal-footer">

              <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                    >
                        {
                            showModal[1] === 'delete' ? 'Cancel Delete' : 'Close'
                        }
                </button>
                
                {showModal[1] === 'delete' && (
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Confirm Delete
                  </button>
                )}
                
               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SESTemplates;
