import React from 'react';

const HtmlSaver = ({ htmlContent }) => {
  const handleDownload = () => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'example.html'; // Filename to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button className='btn btn-success mx-auto' onClick={handleDownload}>Download HTML</button>
    </div>
  );
};

export default HtmlSaver;
