import React, { useState } from 'react';
import { encodeHtmlToBase64 } from '../utils';
import API from "../Services/axios";

const MassMailerTemplate = () => {
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [preview, setPreview] = useState('');
  const [isUpload, setIsUpload] = useState(false);

  const handleHtmlChange = (event) => {
    setHtmlContent(event.target.value);
    setPreview(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setHtmlContent(e.target.result);
        setPreview(e.target.result);
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const encodedHtml = encodeHtmlToBase64(htmlContent);
    const formData = {
      subject,
      name,
      htmlt: encodedHtml
    };
    console.log('Form Data:', formData);

    const res = (await API.post('/createTemplate', formData)).data;

    console.log(res);

    window.alert(res.message || "Check console for response");
    // Handle form submission logic here
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Create a New Template</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            className="form-control"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>HTML Content Source</label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleSwitch"
              checked={isUpload}
              onChange={(e) => setIsUpload(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="toggleSwitch">
              {isUpload ? 'Upload HTML File' : 'Type/Paste HTML Content'}
            </label>
          </div>
        </div>
        {isUpload ? (
          <div className="form-group">
            <label htmlFor="fileUpload">Upload HTML File</label>
            <input
              type="file"
              className="form-control-file"
              id="fileUpload"
              accept=".html"
              onChange={handleFileUpload}
            />
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="htmlt">HTML Content</label>
            <textarea
              className="form-control"
              id="htmlt"
              rows="5"
              value={htmlContent}
              onChange={handleHtmlChange}
              placeholder="Paste HTML content here"
            />
          </div>
        )}
        <div className="form-group">
          <label>Preview</label>
          <div
            className="border p-3"
            dangerouslySetInnerHTML={{ __html: preview }}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default MassMailerTemplate;
