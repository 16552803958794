import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Teams = () => {
  const [formData, setFormData] = useState({});
  const [team, setTeams] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    async function getTeams() {
      const data = (await API.get("/static/team")).data;

      setTeams(data);
    }
    getTeams();
  }, [change]);

  // edit team
  const edit_teams = async (team) => {
    setEdit(true);
    setFormData(team);
  };
  const update_teams = async () => {
    const message = (await API.put(`/static/team/${formData.id}`, formData))
      .data.message;
    setEdit(false);
    setFormData({});
    setChange(!change);
    return message;
  };

  // delete team
  const delete_teams = async (id) => {
    const message = (await API.delete(`/static/team/${id}`)).data.message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const message = edit
      ? update_teams()
      : (
          await API.post("/static/team", {
            ...formData,
          })
        ).data.message;
    alert(message);
    setChange(!change);
  };

  return (
    <div className="container">
      <div className="row">
        {/* team form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Category</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="category"
                value={formData.category}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Official Email</label>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                name="email"
                value={formData.email}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Official Email - Second</label>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                name="email2"
                value={formData.email2}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Linkedin</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={formData.image}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Designation</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="designation"
                value={formData.designation}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">About</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="about"
                value={formData.about}
                onChange={handleInput}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show team  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {team &&
              team
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((team, index) => (
                  <div key={team.id} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOneteam">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapseteam${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapseteam${index}`}
                      >
                        {team.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseteam${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOneteam"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(team).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {team[key].toString()}
                            </div>
                          );
                        })}
                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={team.image}
                            alt={"sponsor"}
                            className="mx-2"
                          >
                            View Image
                          </a>
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_teams(team)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_teams(team.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
