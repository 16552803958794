import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Timeline = ({ accessToken }) => {
  const [formData, setFormData] = useState({});
  // const [checked, setChecked] = useState(false);
  const [timeline, settimeline] = useState([]);
  const [Timeline, setTimeline] = useState();
  const [singletimeline, setSingleTimeline] = useState([]);

  const [image1Arr, setImage1Arr] = useState([]);
  // const [image1url, setImage1url] = useState("");

  const [image2Arr, setImage2Arr] = useState([]);
  // const [image2url, setImage2url] = useState("");

  const [videoArr, setVideoArr] = useState([]);
  const [videourl, setVideourl] = useState("");

  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    async function gettimeline() {
      const data = (await API.get("/static/timeline")).data;
      settimeline(data);
    }
    gettimeline();
  }, [change]);

  // edit timeline
  const edit_timeline = async (data) => {
    setEdit(true);
    setFormData(data);
    setImage1Arr(Timeline.image1);
    setImage2Arr(Timeline.image2);
    setVideoArr(Timeline.videos);
  };

  const getSingleTimeline = async (data) => {
    const single = (await API.get(`/static/timeline/${singletimeline}`)).data;
    setTimeline(single);
  };

  const update_timeline = async () => {
    const message = (
      await API.put(`/static/timeline/${formData.id}`, {
        ...formData,
        image1: image1Arr,
        image2: image2Arr,
        videos: videoArr,
      })
    ).data.message;
    setEdit(false);
    setFormData({});
    setImage1Arr([]);
    setImage2Arr([]);
    setVideoArr([]);
    setChange(!change);
    return message;
  };

  // delete timeline
  const delete_timeline = async (id) => {
    const message = (await API.delete(`/static/timeline/${id}`)).data.message;
    alert(message);
    setChange(!change);
    setImage1Arr([]);
    setImage2Arr([]);
    setVideoArr([]);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // if (videoArr.length > 0) {
    const message = edit
      ? update_timeline()
      : (
          await API.post("/static/timeline", {
            ...formData,
            image1: image1Arr,
            image2: image2Arr,
            videos: videoArr,
          })
        ).data.message;
    alert(message);
    setFormData({});

    setImage1Arr([]);
    setImage2Arr([]);
    setVideoArr([]);
    setChange(!change);
    // } else {
    // alert("Please Fill all the required fields");
    // }
  };
  //   console.log(timeline);
  return (
    <div className="container">
      <div className="row">
        {/* event form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="fw-bold mx-auto">Post a Timeline</div>
            <div className="mb-3">
              <label className="form-label">Edition Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>

            <div className="mb-3">
              <label className="form-label"> Home Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="homeimage"
                value={formData.homeimage}
                onChange={handleInput}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Home page text</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="hometext"
                value={formData.hometext}
                onChange={handleInput}
              />
            </div>

            {/* <div className="mb-3">
              <label className="form-label">Home page bg video link</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="homeviewlink"
                value={formData.homevideolink}
                onChange={handleInput}
              />
            </div> */}

            {/* <div className="mb-3">
              <label className="form-label">Single big gif link </label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="singlegif"
                value={formData.singlegif}
                onChange={handleInput}
              />
            </div> */}

            <div className="mb-3">
              <label className="form-label">Theme</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="theme"
                value={formData.theme}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Tagline</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="tagline"
                value={formData.tagline}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Footfall</label>
              <input
                type="text"
                className="form-control"
                // aria-describedby="emailHelp"
                name="footfall"
                value={formData.footfall}
                onChange={handleInput}
              />
            </div>

            {/* <div className="mb-3">
              <label className="form-label">Images 1 URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image1"
                value={image1url}
                onChange={(e) => {
                  setImage1url(e.target.value);
                }}
              />
              <span
                className="btn cursor-pointer bg-success text-white mt-2"
                onClick={() => {
                  setImage1Arr([...image1Arr, image1url]);
                  setImage1url("");
                }}
              >
                Add Image1 to Array
              </span>
              <span
                className="btn cursor-pointer bg-success text-white mt-2 mx-2"
                onClick={() => {
                  setImage1Arr([]);
                }}
              >
                Remove all Images1 from the Array
              </span>
            </div> */}

            {/* <div className="mb-3">
              <label className="form-label">Single Quote</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="singlequote"
                value={formData.singlequote}
                onChange={handleInput}
              />
            </div> */}

            {/* <div className="mb-3">
              <label className="form-label">Single Heading 2</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="singleheading2"
                value={formData.singleheading2}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Single Description 2</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="singledescription2"
                value={formData.singledescription2}
                onChange={handleInput}
              />
            </div> */}

            {/* <div className="mb-3">
              <label className="form-label">Images 2 URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image2"
                value={image2url}
                onChange={(e) => {
                  setImage2url(e.target.value);
                }}
              />
              <span
                className="btn cursor-pointer bg-success text-white mt-2"
                onClick={() => {
                  setImage2Arr([...image2Arr, image2url]);
                  setImage2url("");
                }}
              >
                Add Image2 to Array
              </span>
              <span
                className="btn cursor-pointer bg-success text-white mt-2 mx-2"
                onClick={() => {
                  setImage2Arr([]);
                }}
              >
                Remove all Images2 from the Array
              </span>
            </div> */}

            <div className="mb-3">
              <label className="form-label">Video URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="Video"
                value={videourl}
                onChange={(e) => {
                  setVideourl(e.target.value);
                }}
              />
              <span
                className="btn cursor-pointer bg-success text-white mt-2"
                onClick={() => {
                  setVideoArr([...videoArr, videourl]);
                  setVideourl("");
                }}
              >
                Add Video to Array
              </span>
              <span
                className="btn cursor-pointer bg-success text-white mt-2 mx-2"
                onClick={() => {
                  setVideoArr([]);
                }}
              >
                Remove all Videos from the Array
              </span>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show all timeline  */}
        <div
          className="col-lg-6 card mt-1 mb-1 p-1"
          style={{ maxHeight: "400px", overflowY: "scroll" }}
        >
          <div className="fw-bold mx-auto">Get All timeline</div>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {timeline &&
              timeline
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((data, idx) => (
                  <div key={idx} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${idx}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${idx}`}
                      >
                        {data.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${idx}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(data).map((key, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {data[key].toString()}
                            </div>
                          );
                        })}

                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_timeline(data)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_timeline(idx)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>

      {/* show single timeline */}
      <div
        className="col-lg-6 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Single Timeline</div>
        <div className="input-group mb-3">
          <input
            type="text"
            id="categoryinput"
            className="form-control"
            placeholder="Category Name"
            aria-label="Category Name"
            aria-describedby="button-addon2"
            onChange={(e) => {
              setSingleTimeline(e.target.value);
            }}
          />
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={getSingleTimeline}
          >
            Button
          </button>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {Timeline && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOnecategory">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapsecategory`}
                  aria-expanded="false"
                  aria-controls={`flush-collapsecategory`}
                >
                  {Timeline.name}
                </button>
              </h2>
              <div
                id={`flush-collapsecategory`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOnelive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  {Object.keys(Timeline).map((key, index) => {
                    return (
                      <div className="d-flex" key={index}>
                        <span className="mx-2">{key} :</span>
                        {Timeline[key].toString()}
                      </div>
                    );
                  })}
                  <div className="d-flex">
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => edit_timeline(Timeline)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-2"
                      onClick={() => delete_timeline(Timeline.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
