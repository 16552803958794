import React, { useState, useEffect } from "react";
import API from "../Services/axios";

export default function NewsLetter() {
  const [users, setUsers] = useState([]);
  const [change, setChange] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      const userData = (await API.get("/newsletter")).data;
      setUsers(userData);
    }
    fetchUserData();
  }, [change]);
  console.log(users);

  const unSubscribe = async (email) => {
    const res = (await API.delete(`/newsletter/${email}`)).data.message;
    alert(res);
    setChange(!change);
  };

  return (
    <div>
      <h1 className="text-center mt-4">NewsLetter Subscribers</h1>
      <div
        className="mx-auto border border-dark mt-5 rounded w-50 "
        style={{ maxHeight: "500px", overflowY: "scroll" }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User</th>
              <th scope="col">Unsubscribe</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((user, index) => {
                return (
                  <>
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{user.email}</td>
                      <td>
                        <div
                          className="btn btn-danger"
                          onClick={() => {
                            unSubscribe(user.email);
                          }}
                        >
                          UnSubscribe
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
