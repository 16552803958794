import React, { useState, useEffect } from 'react';
import API from '../Services/axios';

const MassMailerForm = () => {
  const [emails, setEmails] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [templateData, setTemplateData] = useState('');
  const [templates, setTemplates] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = (await API.get('/listtemplates')).data;
      console.log(response)
      if(response.data.res.TemplatesMetadata)
      setTemplates(response.data.res.TemplatesMetadata)
      setTemplateData(templates[0].Name)
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleEmailsChange = (event) => {
    const { value } = event.target;
    const emailArray = value.split(',').map(email => email.trim());
    setEmails(emailArray);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleTemplateDataChange = (event) => {
    setTemplateData(event.target.value);
  };

  const validateJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Invalid JSON format');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    if(!emails.length){
        setErrorMessage('Please enter email addresses');
        return;
    }

    if(!templateName){
        setErrorMessage('Please select a template');
        return;
    }

    const requestData = {
      emails,
      templateName,
      template_data: JSON.parse(templateData)
    };

    console.log(requestData)

    try {
      const response = await API.post('/templatedMassMailer', requestData);

      console.log('Response:', response.data);
      setSuccessMessage('Emails sent successfully');
    } catch (error) {
      console.error('Error sending emails:', error);
      setErrorMessage('Failed to send emails');
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Mass Mailer</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="emails">Email Addresses (comma-separated)</label>
          <textarea
            className="form-control"
            id="emails"
            rows="3"
            value={emails.join(', ')}
            onChange={handleEmailsChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="templateName">Template Name</label>
          <select
            className="form-control"
            id="templateName"
            value={templateName}
            onChange={handleTemplateNameChange}
            required
            >
                <option selected disabled value="">Select a template</option>
            {templates.map((template) => (
                <option key={template.Name} value={template.Name}>
                    {template.Name}
                </option>
                ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="templateData">Template Data (JSON)</label>
          <textarea
            className="form-control"
            id="templateData"
            rows="5"
            value={templateData}
            onChange={(e) => {
              setTemplateData(e.target.value);
              validateJson(e.target.value);
            }}
            required
          />
          {errorMessage && (
            <div className="alert alert-danger mt-2" role="alert">
              {errorMessage}
            </div>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Send Emails
        </button>
        {successMessage && (
          <div className="alert alert-success mt-3" role="alert">
            {successMessage}
          </div>
        )}
      </form>
    </div>
  );
};

export default MassMailerForm;
