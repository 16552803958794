import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../../Services/axios";

const CapClans = ({ accessToken }) => {
  const [clansFormData, setClanFormData] = useState({});
  const [capclans, setCapClans] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    async function getCapClans() {
      const data = (await API.get("/static/capclans")).data;

      setCapClans(data);
    }
    getCapClans();
  }, [change]);

  // edit capclans
  const edit_capclans = async (clan) => {
    setEdit(true);
    setClanFormData(clan);
  };
  const update_capclans = async () => {
    const message = (
      await API.put(`/static/capclans/${clansFormData.id}`, {
        ...clansFormData,
      })
    ).data.message;
    setEdit(false);
    setClanFormData({});
    setChange(!change);
    return message;
  };

  // delete capclans
  const delete_capclans = async (id) => {
    const message = (await API.delete(`/static/capclans/${id}`)).data
      .message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setClanFormData({
      ...clansFormData,
      [e.target.name]: e.target.value,
    });
  };
  const onClanSubmit = async (e) => {
    e.preventDefault();
    const message = edit
      ? update_capclans()
      : (
          await API.post("/static/capclans", {
            ...clansFormData,
          })
        ).data.message;
    alert(message);
    setChange(!change);
  };

  return (
    <div className="container">
      <h1>CAP Clans</h1>
      <div className="row">
        {/* clan form  */}
        <div className="container col-lg-5">
          <form onSubmit={onClanSubmit}>
            <div className="mb-3">
              <label className="form-label">Clans Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={clansFormData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Points</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="points"
                value={clansFormData.points}
                onChange={handleInput}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show capclans  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {capclans &&
              capclans.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((clan) => (
                <div key={clan.id} className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${clan.id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${clan.id}`}
                    >
                      {clan.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${clan.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(clan).map((key, index) => {
                        if (key === "image") {
                          return "";
                        }
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {clan[key].toString()}
                          </div>
                        );
                      })}

                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          href={clan.image}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          View Image
                        </a>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_capclans(clan)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() => delete_capclans(clan.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapClans;
