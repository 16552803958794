import React from "react";

export default function LiveEvents({
  liveevents,
  edit_events,
  delete_events,
  setLiveCategory,
  eventscategory,
  getLiveByCategory,
  livecategoryevents,
}) {
  return (
    <>
      <div className="mx-auto fs-3 fw-bold text-center mb-5">
        GET LIVE EVENTS
      </div>

      <div
        className="col-lg-5 card mt-1 mb-2 mx-5 p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">All Live Events</div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {liveevents &&
            liveevents
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((event, idx) => (
                <div key={idx} className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOnelive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapselive${idx}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapselive${idx}`}
                    >
                      {event.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapselive${idx}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOnelive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {/* {event.keys.map((data) => {
                        return (
                          <div className="d-flex">
                            <span className="mx-2">{data} :</span>
                            {data}
                          </div>
                        );
                      })} */}

                      {Object.keys(event).map((key, index) => {
                        if (key === "image") {
                          return "";
                        } else if (key === "sponsors") {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {event[key].map((sponsor, index) => {
                                return (
                                  <span className="" key={index}>
                                    {sponsor.label},
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {JSON.stringify(event[key], null, 2)}
                            </div>
                          );
                        }
                      })}
                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.image}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>

                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_events(event)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() =>
                            delete_events(event.id, event.category)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      {/* show live events by category */}
      <div
        className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Live Events by Category</div>
        <div className="input-group mb-3">
          <select
            className="form-select"
            onChange={(e) => {
              setLiveCategory(e.target.value);
            }}
          >
            <option value="" selected disabled>
              Select Category
            </option>

            {eventscategory.map((category, index) => {
              return (
                <option
                  key={index}
                  value={category.name}
                  name="category"
                  className="form-control"
                >
                  {category.name}
                </option>
              );
            })}
          </select>
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={getLiveByCategory}
          >
            Button
          </button>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {livecategoryevents &&
            livecategoryevents.map((event, idx) => (
              <div key={idx} className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingOnelivecategory"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapselivecategory${idx}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapselivecategory${idx}`}
                  >
                    {event.name}
                  </button>
                </h2>
                <div
                  id={`flush-collapselivecategory${idx}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOnelive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {Object.keys(event).map((key, index) => {
                      if (key === "image") {
                        return "";
                      } else if (key === "sponsors") {
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {event[key].map((sponsor, index) => {
                              return (
                                <span className="" key={index}>
                                  {sponsor.label},
                                </span>
                              );
                            })}
                          </div>
                        );
                      } else {
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {JSON.stringify(event[key], null, 2)}
                          </div>
                        );
                      }
                    })}
                    <div className="d-flex">
                      <span className="mx-2">Image :</span>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={event.image}
                        alt={"sponsor"}
                        className="mx-2"
                      >
                        View Image
                      </a>
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-warning"
                        onClick={() => edit_events(event)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm btn-danger mx-2"
                        onClick={() => delete_events(event.id, event.category)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
