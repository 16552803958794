import React from "react";
export default function EventsCategory({categoryformData,handleCategoryInput,onCategorySubmit,eventscategory,delete_eventscategory}) {
  return (
    <>
      {/* event category form  */}
      <div className="mx-auto fs-3 fw-bold text-center mb-5">
        EVENTS CATEGORIES
      </div>
      <div className="container col-lg-5">
        <div className="fw-bold mx-auto">Post an Event Category</div>
        <div className="mb-3">
          <label className="form-label">Category Name</label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            name="name"
            value={categoryformData.name}
            onChange={handleCategoryInput}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Image URL</label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            name="image"
            value={categoryformData.image}
            onChange={handleCategoryInput}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Category Cover Image</label>
          <input
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            name="cover"
            value={categoryformData.cover}
            onChange={handleCategoryInput}
          />
        </div>

        <div onClick={onCategorySubmit} className="btn btn-primary">
          Submit
        </div>
      </div>

      {/* show all event categories  */}
      <div
        className="col-lg-6 card mt-1 mb-1 p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get All Event Categories</div>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {eventscategory &&
            eventscategory.map((event, idx) => (
              <div key={idx} className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOnecat">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapsecat${idx}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapsecat${idx}`}
                  >
                    {event.name}
                  </button>
                </h2>
                <div
                  id={`flush-collapsecat${idx}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOnecat"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {Object.keys(event).map((key, index) => {
                      if (key === "image" || key === "cover") {
                        return "";
                      } else if (key === "sponsors") {
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {event[key].map((sponsor, index) => {
                              return (
                                <span className="" key={index}>
                                  {sponsor.label},
                                </span>
                              );
                            })}
                          </div>
                        );
                      } else if (key === "reqdfields") {
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {event[key].map((reqdfields, index) => {
                              return (
                                <span className="" key={index}>
                                  {reqdfields.label},
                                </span>
                              );
                            })}
                          </div>
                        );
                      }
                      return (
                        <div className="d-flex" key={index}>
                          <span className="mx-2">{key} :</span>
                          {event[key].toString()}
                        </div>
                      );
                    })}
                    <div className="d-flex">
                      <span className="mx-2">Image :</span>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                       
                        href={event.image}
                        alt={"sponsor"}
                        className="mx-2"
                      >
                        View Image
                      </a>
                    </div>
                    {event.cover ? (
                      <div className="d-flex">
                        <span className="mx-2">Cover Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                       
                          href={event.cover}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>
                    ) : null}
                    <div className="d-flex">
                      <button
                        className="btn btn-sm btn-danger mx-2"
                        onClick={() => delete_eventscategory(event.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
