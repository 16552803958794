import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../../Services/axios";
import CsvDownload from "react-json-to-csv";
import Select from "react-select";
import Papa from "papaparse";

const Events_User = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  // const [email, setEmail] = useState();
  // const [singleuser, setSingleUser] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const getUsers = async () => {
    const data = (await API.get(`/users/cap`)).data;
    setUsers(data);
    setCount(data.length);
    const options = data.map((user) => ({
      value: user.email,
      label: user.name + " - " + user.email + " - " + user.aaruushId,
      points: user.ca_points,
    }));
    setUserOptions(options);
  };
  console.log(userOptions);
  const [selectedUser, setSelectedUser] = useState(null);
  const [Points, setPoints] = useState(null);
  // const getSingleUser = async (e) => {
  //     if (email) {
  //         e.preventDefault();
  //         const data = (
  //             await API.get(`/users/${email}`)
  //         ).data;
  //         setSingleUser(data);
  //     } else {
  //         alert("Please Fill all required fields");
  //     }
  // };

  const updatePoints = async () => {
    if (Points) {
      const data = await API.put(`/users/ca_points`, {
        ca_points: Points,
        email: selectedUser.value,
      });
      if (data.data.message === "Unauthorized") {
        window.alert("Unauthorized");
        return;
      } else if (data.status === 202) {
        window.alert("Points Updated");
        setSelectedUser(null);
        getUsers();
      }
      // console.log(data);
      // window.alert("Points Updated");
      // getUsers();
    } else {
      window.alert("Please Fill all required fields");
    }
  };
  const [bulkData, setBulkData] = useState(null);
  const bulkUpdatePoints = async () => {
    if (bulkData) {
      const data = await API.put(`/users/ca_points/bulk`, {
        users: bulkData,
      });
      console.log(data);
      if (data.data.message === "Unauthorized") {
        window.alert("Unauthorized");
        return;
      } else if (data.status === 202) {
        window.alert("Points Updated");
        setBulkData(null);
        getUsers();
      }
    } else {
      window.alert("Please Fill all required fields");
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (selectedUser && selectedUser.points) {
      setPoints(selectedUser.points);
    } else {
      setPoints(0);
    }
    // setPoints(selectedUser?.points ? selectedUser?.points : 0);
  }, [selectedUser]);
  console.log(selectedUser);

  const downloadCSV = (data) => {
    // Define the keys you want to include in the CSV
    const keysToInclude = ["name", "email", "aaruushId", "ca_points"];

    const newObj = {};

    // Iterate over the keys to include
    const filteredData = data.map((obj) => {
      const newObj = {};

      // Iterate over the keys to include
      keysToInclude.forEach((key) => {
        // Check if the key exists in the original data
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = obj[key];
        } else {
          newObj[key] = "0"; // Set default value as "0" for missing keys
        }
      });

      return newObj;
    });

    // Convert filtered data to CSV format using PapaParse
    const csv = Papa.unparse(filteredData, {
      header: true,
    });

    // Create a temporary anchor element to trigger the file download
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = `AARUUSH-${new Date().toLocaleDateString()}-CAPPoints.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;

      Papa.parse(csvData, {
        header: true,
        complete: (results) => {
          const keysToCheck = ["name", "email", "aaruushId", "ca_points"]; // Assuming you have the list of keys to check as a prop
          const jsonData = [];

          results.data.forEach((row) => {
            const isValid = keysToCheck.every((key) => row.hasOwnProperty(key));

            if (isValid) {
              jsonData.push(row);
            }
          });
          // filter data to remove ca_points 0
          const filteredData = jsonData.filter((obj) => {
            return obj.ca_points !== "0";
          });

          setBulkData(filteredData);
          // console.log(filteredData); // Do whatever you want with the converted JSON data
        },
      });
    };

    reader.readAsText(file);
  };
  return (
    <>
      <div className="col-lg-7">
        <Select
          options={userOptions}
          // onChange={(e) => setEmail(e.value)}
          onChange={(e) => setSelectedUser(e)}
        />
        {selectedUser && (
          <div>
            <div className="card mt-1 mb-1 mx-auto p-1">
              <div className="card-body">
                <h5 className="card-title">
                  Name: {selectedUser.label.split(" - ")[0]}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  Email: {selectedUser.label.split(" - ")[1]}
                </h6>
                <h6 className="card-subtitle mb-2 text-muted">
                  Aaruush ID: {selectedUser.label.split(" - ")[2]}
                </h6>
                <h6 className="card-subtitle mb-2 text-muted">
                  Points: {selectedUser.points}
                </h6>
                {/* add a field to update the ca_points */}
                <input
                  type="text"
                  onChange={(e) => setPoints(e.target.value)}
                  value={Points}
                  //   value={selectedUser.points ? selectedUser.points : 0}
                />
                <button className="btn btn-primary" onClick={updatePoints}>
                  Update Points
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <div
          className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
          style={{ maxHeight: "400px", overflowY: "scroll" }}
        >
        </div> */}
        <hr />
        <h5>Total Users: {count}</h5>
        <hr />
        <h3>Bulk Update Data</h3>
        <button
          className="btn btn-secondary"
          data={users}
          onClick={() => downloadCSV(users)}
        >
          Download Data as CSV
        </button>

        {/* add input tag to upload csv file*/}
        <input type="file" accept=".csv" onChange={handleCSVUpload} />
        {bulkData && (
          <button className="btn btn-primary" onClick={bulkUpdatePoints}>
            Bulk Update Points
          </button>
        )}
        <hr />
      </div>
    </>
  );
};

export default Events_User;
