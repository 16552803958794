import React, { useEffect } from "react";
import API from "../Services/axios";
import BlogEditorComponent from "./BlogEditorComponent";

const Blogs = () => {
  // API.get('/blog/)
  const [blogs, setBlogs] = React.useState([]);

  useEffect(() => {
    API.get("/blog").then((res) => {
      console.log(res.data.data);
      setBlogs(res.data.data);
    });
  }, []);

  const [formData, setFormData] = React.useState();
  const edit_blogs = async (blog) => {
    // setEdit(true);
    setShow(true);
    setFormData(blog);
  };

  const delete_blogs = async (id) => {
    // console.log(id);
    const message = (await API.delete(`/blog/${id}`)).data.message;
    alert(message);
    // setChange(!change);
  };
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="container p-3 my-3 border">Blogs</div>
      {/* <button type="button" className="btn btn-primary" onClick={handleShow}>
        Launch demo modal
      </button> */}

      {show && (
        <div
          className="modal fade show"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{formData.title}</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <BlogEditorComponent blogEditData={formData} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClose}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {blogs && */}
      {/* blogs.map((blog, idx) => { */}
      {/* return ( */}
      {/* <div
        className="accordion accordion-flush"
        id="accordionFlushExample"
        key={idx}
      > */}
      {blogs &&
        blogs
          .sort(function(a, b) {
            return a.date - b.date;
          })
          .reverse()
          .map((blog, idx) => (
            <div key={idx} className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOnelive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapselive${idx}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapselive${idx}`}
                >
                  {blog.title}
                </button>
              </h2>
              <div
                id={`flush-collapselive${idx}`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOnelive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  {/* {blog.keys.map((data) => {
                        return (
                          <div className="d-flex">
                            <span className="mx-2">{data} :</span>
                            {data}
                          </div>
                        );
                      })} */}
                  <div>
                    <div className="d-flex">
                      <span className="mx-2">Title :</span>

                      <span className="">{blog.title}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Author :</span>

                      <span className="">{blog.author}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Date :</span>

                      <span className="">{blog.date}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Pk :</span>

                      <span className="">{blog.pk}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">SK :</span>

                      <span className="">{blog.sk}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Id :</span>

                      <span className="">{blog.id}</span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Header :</span>

                      <span className="">
                        <a href={blog.headerImage} target="_blank">
                          Header Image
                        </a>
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="mx-2">Body :</span>

                      <span className="">{blog.raw}</span>
                    </div>
                  </div>

                  {/* <div className="d-flex">
                            <span className="mx-2">Image :</span>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={blog.image}
                              alt={"sponsor"}
                              className="mx-2"
                            >
                              View Image
                            </a>
                          </div> */}

                  <div className="d-flex">
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => edit_blogs(blog)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-2"
                      onClick={() => delete_blogs(blog.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      {/* </div> */}
      {/* ); */}
      {/* })} */}
    </div>
  );
};
export default Blogs;
