import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import API from "../Services/axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import EventsCategory from "./Events/EventsCategory";
import EventsComp from "./Events/Events";
import LiveEvents from "./Events/GetLiveEvents";
import GetEvents from "./Events/GetEvents";

const animatedComponents = makeAnimated();

const Events = () => {
  const [formData, setFormData] = useState({});
  const [checked, setChecked] = useState(true);
  const [dynamicForm, setDynamicForm] = useState(true);
  const [events, setEvents] = useState([]);
  const [liveevents, setLiveEvents] = useState([]);

  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [category, setCategory] = useState(null);
  const [categoryevents, setCategoryEvents] = useState([]);
  const [livecategory, setLiveCategory] = useState(null);
  const [livecategoryevents, setLiveCategoryEvents] = useState([]);
  const [singleid, setSingleId] = useState(null);
  const [singlecategory, setSingleCategory] = useState(null);
  const [singleevent, setSingleEvent] = useState();

  const [categoryformData, setCategoryFormData] = useState({});
  const [eventscategory, setEventsCategory] = useState([]);

  // const [eventcategory, setEventCategory] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [newfield, setNewField] = useState("");

  const [imageArr, setImageArr] = useState([]);
  const [imageurl, setImageurl] = useState("");

  useEffect(() => {
    async function getEvents() {
      const data = (await API.get("/events")).data;

      setEvents(data);
    }
    getEvents();
  }, [change]);

  let options = sponsors.map((data, index) => {
    return {
      label: data.name,
      value: data.image,
    };
  });

  let categoryoptions = eventscategory.map((data, index) => {
    return {
      label: data.name,
      value: data.name,
    };
  });

  var regdoptions = [
    {
      label: "name",
      value: "name",
    },
    {
      label: "email",
      value: "email",
    },
    {
      label: "phone",
      value: "phone",
    },
    {
      label: "college",
      value: "college",
    },
    {
      label: "age",
      value: "age",
    },
    {
      label: "food",
      value: "food",
    },
  ];
  useEffect(() => {
    async function getLiveEvents() {
      const data = (await API.get("/events/live")).data;

      setLiveEvents(data);
    }
    getLiveEvents();
  }, [change]);

  useEffect(() => {
    async function getEventsCategory() {
      const data = (await API.get("/static/EventCategory")).data;
      setEventsCategory(data);
    }
    getEventsCategory();
  }, [change]);

  useEffect(() => {
    async function getSponsors() {
      const data = (await API.get("/static/sponsors")).data;

      setSponsors(data);
    }
    getSponsors();
  }, [change]);

  const getByCategory = async () => {
    const data = (await API.get(`/events/${category}`)).data;

    setCategoryEvents(data);
  };

  const getLiveByCategory = async () => {
    const data = (await API.get(`/events/${livecategory}/live`)).data;

    setLiveCategoryEvents(data);
  };

  const getSingleEvent = async () => {
    const data = (await API.get(`/events/events/${singleid}`)).data;

    setSingleEvent(data);
  };

  const edit_events = async (event) => {
    setEdit(true);
    setFormData(event);
    setChecked(event.live);
  };
  console.log(eventscategory);

  const update_event = async () => {
    console.log(formData, checked);
    const message = (
      await API.put(`/events/${formData.category}/${formData.id}`, {
        ...formData,
        live: checked ? true : false,
        gallery: imageArr,
      })
    ).data.message;
    setEdit(false);
    setFormData({});
    setImageArr([]);
    setChange(!change);
    console.log(message);
    return message;
  };

  const delete_events = async (id, eventcategory) => {
    console.log(id, eventcategory);
    const message = (await API.delete(`/events/events/${id}`)).data.message;
    alert(message);
    setChange(!change);
  };

  const delete_eventscategory = async (id) => {
    const message = (await API.delete(`/static/EventCategory/${id}`)).data
      .message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePaymentType = (e) => {
    setFormData({
      ...formData,
      payment_type: e.value,
    });
  };
  const handleEventCategoryChange = (e) => {
    setFormData({
      ...formData,
      category: e.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(checked);
    const message = edit
      ? update_event()
      : (
          await API.post("/events", {
            ...formData,
            live: checked ? true : false,
            reqdfields: dynamicForm ? true : false,
            gallery: imageArr,
            category: "events",
          })
        ).data.message;
    alert(message);
    setFormData({});
    setImageArr([]);
    setChange(!change);
  };
  const handleCategoryInput = (e) => {
    setCategoryFormData({
      ...categoryformData,
      [e.target.name]: e.target.value,
    });
  };

  const onCategorySubmit = async (e) => {
    if (categoryformData) {
      e.preventDefault();
      const message = (
        await API.post("/static/EventCategory", {
          ...categoryformData,
        })
      ).data.message;
      alert(message);
      setCategoryFormData({});
      setChange(!change);
    } else {
      alert("Please Fill all required category fields ");
    }
  };

  function deletenewfield(e) {
    var thisElement = e.path[2];

    thisElement.remove();
  }
  function addNewField(field) {
    // Define the Elements
    var dest = document.getElementById("newblock");
    var element = document.createElement("div");
    var label = document.createElement("label");
    var group = document.createElement("div");
    var input = document.createElement("input");
    var span = document.createElement("span");
    // Create the Input element
    input.className = "form-control";
    input.name = field;
    input.value = formData[field];
    input.onchange = handleInput;
    // Create the Close Button
    span.classList.add("input-group-text");
    span.classList.add("btn");
    span.classList.add("btn-outline-danger");
    span.onclick = (e) => deletenewfield(e);
    span.innerText = "Remove Field";
    // Create the group for the close button and input
    group.className = "input-group";
    group.id = "form-" + field;
    // Create Label element
    label.className = "form-label";
    label.innerText = field;
    // Append Children
    element.appendChild(label);
    group.appendChild(input);
    group.appendChild(span);
    element.appendChild(group);

    // Append the element to the form
    dest.appendChild(element);
  }

  return (
    <div className="container">
      <div className="row">
        <EventsCategory
          categoryformData={categoryformData}
          handleCategoryInput={handleCategoryInput}
          onCategorySubmit={onCategorySubmit}
          eventscategory={eventscategory}
          delete_eventscategory={delete_eventscategory}
        />
        <hr className="m-3" />
        <EventsComp
          onSubmit={onSubmit}
          formData={formData}
          handleInput={handleInput}
          Select={Select}
          animatedComponents={animatedComponents}
          categoryoptions={categoryoptions}
          handleEventCategoryChange={handleEventCategoryChange}
          options={options}
          setFormData={setFormData}
          CreatableSelect={CreatableSelect}
          regdoptions={regdoptions}
          handlePaymentType={handlePaymentType}
          checked={checked}
          setChecked={setChecked}
          setNewField={setNewField}
          addNewField={addNewField}
          newfield={newfield}
          events={events}
          edit_events={edit_events}
          delete_events={delete_events}
          imageArr={imageArr}
          setImageArr={setImageArr}
          imageurl={imageurl}
          setImageurl={setImageurl}
          dynamicForm={dynamicForm}
          setDynamicForm={setDynamicForm}
        />
        <hr className="m-3" />

        <LiveEvents
          liveevents={liveevents}
          edit_events={edit_events}
          delete_events={delete_events}
          setLiveCategory={setLiveCategory}
          eventscategory={eventscategory}
          getLiveByCategory={getLiveByCategory}
          livecategoryevents={livecategoryevents}
        />
        <hr className="m-3" />
        <GetEvents
          setCategory={setCategory}
          getByCategory={getByCategory}
          eventscategory={eventscategory}
          edit_events={edit_events}
          categoryevents={categoryevents}
          delete_events={delete_events}
          setSingleCategory={setSingleCategory}
          setSingleId={setSingleId}
          getSingleEvent={getSingleEvent}
          singleevent={singleevent}
        />
      </div>
      <hr className="m-3" />
    </div>
  );
};

export default Events;
