import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../../Services/axios";

const CapWeeklyQuestions = ({ accessToken }) => {
  const [weeklyQuestionFormData, setWeeklyQuestionFormData] = useState({});
  const [capweeklyquestions, setCapWeeklyQuestions] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    async function getCapWeeklyQuestions() {
      const data = (await API.get("/static/capweeklyquestions")).data;

      setCapWeeklyQuestions(data);
    }
    getCapWeeklyQuestions();
  }, [change]);

  // edit capweeklyquestions
  const edit_capweeklyquestions = async (question) => {
    setEdit(true);
    setWeeklyQuestionFormData(question);
  };
  const update_capweeklyquestions = async () => {
    const message = (
      await API.put(`/static/capweeklyquestions/${weeklyQuestionFormData.id}`, {
        ...weeklyQuestionFormData,
        show: checked ? true : false,
      })
    ).data.message;
    setEdit(false);
    setWeeklyQuestionFormData({});
    setChange(!change);
    return message;
  };

  // delete capweeklyquestions
  const delete_capweeklyquestions = async (id) => {
    const message = (await API.delete(`/static/capweeklyquestions/${id}`)).data
      .message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setWeeklyQuestionFormData({
      ...weeklyQuestionFormData,
      [e.target.name]: e.target.value,
    });
  };
  const onWeeklyQuestionSubmit = async (e) => {
    e.preventDefault();
    const message = edit
      ? update_capweeklyquestions()
      : (
          await API.post("/static/capweeklyquestions", {
            ...weeklyQuestionFormData,
            show: checked ? true : false,
          })
        ).data.message;
    alert(message);
    setChange(!change);
  };

  

  return (
    <div className="container">
      <h1>CAP Weekly Questions</h1>
      <div className="row">
        {/* question form  */}
        <div className="container col-lg-5">
          <form onSubmit={onWeeklyQuestionSubmit}>
            <div className="mb-3">
              <label className="form-label">Question no.</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={weeklyQuestionFormData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Question</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="question"
                value={weeklyQuestionFormData.question}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Answer Type</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="answer_type"
                placeholder={"text or bool"}
                value={weeklyQuestionFormData.answer_type}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <label className="form-check-label">Show</label>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show capweeklyquestions  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {capweeklyquestions &&
              capweeklyquestions.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((question) => (
                <div key={question.id} className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${question.id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${question.id}`}
                    >
                      {question.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${question.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(question).map((key, index) => {
                        if (key === "image") {
                          return "";
                        }
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {question[key].toString()}
                          </div>
                        );
                      })}

                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          href={question.image}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          View Image
                        </a>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_capweeklyquestions(question)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() => delete_capweeklyquestions(question.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapWeeklyQuestions;
