import React from "react";
// import UploadImage from "./UploadImage";
// import API from "../Services/axios";
import WeeklyQuestions from "./CAP/WeeklyQuestions";
import Tasks from "./CAP/Tasks";
import Clans from "./CAP/Clans";
const CapWeeklyQuestions = ({ accessToken }) => {
  return (
    <div className="container">
      <WeeklyQuestions accessToken={accessToken} />
      <Tasks accessToken={accessToken} />
      <Clans accessToken={accessToken} />
      
    </div>
  );
};

export default CapWeeklyQuestions;
