import React from "react";

export default function GetEvents({
  setCategory,
  getByCategory,
  eventscategory,
  edit_events,
  categoryevents,
  delete_events,
  setSingleCategory,
  setSingleId,
  getSingleEvent,
  singleevent,
}) {
  return (
    <>
      {/* show events by category */}
      <div className="mx-auto fs-3 fw-bold text-center mb-5">GET EVENTS</div>

      <div
        className="col-lg-6 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Events by Category</div>
        <div className="input-group mb-3">
          <select
            className="form-select"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option value="" selected disabled>
              Select Category
            </option>

            {eventscategory
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((category, index) => {
                return (
                  <option
                    key={index}
                    value={category.name}
                    name="category"
                    className="form-control"
                  >
                    {category.name}
                  </option>
                );
              })}
          </select>
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={getByCategory}
          >
            Submit
          </button>
        </div>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {categoryevents &&
            categoryevents
              .sort(function(a, b) {
                return a.timestamp - b.timestamp;
              })
              .reverse()
              .map((event, idx) => (
                <div key={idx} className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingOnecategory"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapsecategory${idx}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapsecategory${idx}`}
                    >
                      {event.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapsecategory${idx}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOnelive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(event).map((key, index) => {
                        if (key === "image") {
                          return "";
                        } else if (key === "sponsors") {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {event[key].map((sponsor, index) => {
                                return (
                                  <span className="" key={index}>
                                    {sponsor.label},
                                  </span>
                                );
                              })}
                            </div>
                          );
                        } else {
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {JSON.stringify(event[key], null, 2)}
                            </div>
                          );
                        }
                      })}
                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.image}
                          alt={"sponsor"}
                          className="mx-2"
                        >
                          View Image
                        </a>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_events(event)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() =>
                            delete_events(event.id, event.category)
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
      {/* show single event */}
      <div
        className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <div className="fw-bold mx-auto">Get Single Event</div>
        <div className="input-group mb-3">
          <div className="row">
            <div className="col">
              <select
                className="form-select"
                onChange={(e) => {
                  setSingleCategory(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Category
                </option>
                {eventscategory
                  .sort(function(a, b) {
                    return a.timestamp - b.timestamp;
                  })
                  .reverse()
                  .map((category, index) => {
                    return (
                      <option
                        key={index}
                        value={category.name}
                        name="category"
                        className="form-control"
                      >
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col">
              <input
                type="text"
                id="categorysingeinput"
                className="form-control"
                placeholder="Event ID"
                aria-label="Event ID"
                aria-describedby="button-addon2"
                onChange={(e) => {
                  setSingleId(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                onClick={getSingleEvent}
              >
                Button
              </button>
            </div>
          </div>
        </div>

        {singleevent && (
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2
                className="accordion-header"
                id="flush-headingOnelivecategory"
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapselivecategory`}
                  aria-expanded="false"
                  aria-controls={`flush-collapselivecategory`}
                >
                  {singleevent.name}
                </button>
              </h2>
              <div
                id={`flush-collapselivecategory`}
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOnelive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  {Object.keys(singleevent).map((key, index) => {
                    if (key === "image") {
                      return "";
                    }
                    return (
                      <div className="d-flex" key={index}>
                        <span className="mx-2">{key} :</span>
                        {singleevent[key].toString()}
                      </div>
                    );
                  })}

                  <div className="d-flex">
                    <span className="mx-2">Image :</span>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={singleevent.image}
                      alt={"sponsor"}
                      className="mx-2"
                    >
                      View Image
                    </a>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => edit_events(singleevent)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-2"
                      onClick={() =>
                        delete_events(singleevent.id, singleevent.category)
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
