import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../../Services/axios";

const CapTasks = ({ accessToken }) => {
  const [TasksFormData, setTasksFormData] = useState({});
  const [captasks, setCapTasks] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  // const [checked, setChecked] = useState(true);

  useEffect(() => {
    async function getCapTasks() {
      const data = (await API.get("/static/captasks")).data;

      setCapTasks(data);
    }
    getCapTasks();
  }, [change]);

  // edit captasks
  const edit_captasks = async (tasks) => {
    setEdit(true);
    setTasksFormData(tasks);
  };
  const update_captasks = async () => {
    const message = (
      await API.put(`/static/captasks/${TasksFormData.id}`, {
        ...TasksFormData,
        // show: checked ? true : false,
      })
    ).data.message;
    setEdit(false);
    setTasksFormData({});
    setChange(!change);
    return message;
  };

  // delete captasks
  const delete_captasks = async (id) => {
    const message = (await API.delete(`/static/captasks/${id}`)).data.message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setTasksFormData({
      ...TasksFormData,
      [e.target.name]: e.target.value,
    });
  };
  const onTasksSubmit = async (e) => {
    e.preventDefault();
    if (TasksFormData.upload_link) {

      const message = edit
        ? update_captasks()
        : (
          await API.post("/static/captasks", {
            ...TasksFormData,
            // show: checked ? true : false,
          })
        ).data.message;
      alert(message);
      setChange(!change);
    }
    else {
      alert("Please add upload link for the task")
    }
  };

  return (
    <div className="container">
      <h1>CAP Tasks</h1>
      <div className="row">
        {/* tasks form  */}
        <div className="container col-lg-5">
          <form onSubmit={onTasksSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="name"
                value={TasksFormData.name}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Task no.</label>
              <input
                type="number"
                className="form-control"
                aria-describedby="emailHelp"
                value={TasksFormData.number}
                name="number"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="description"
                value={TasksFormData.description}
                onChange={handleInput}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Deadline</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="deadline"
                value={TasksFormData.deadline}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Points</label>
              <input
                type="number"
                className="form-control"
                aria-describedby="emailHelp"
                name="points"
                value={TasksFormData.points}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={TasksFormData.image}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Link</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="upload_link"
                value={TasksFormData.upload_link}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Facebook</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="facebook"
                value={TasksFormData.facebook}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Instagram</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="instagram"
                value={TasksFormData.instagram}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Twitter</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="twitter"
                value={TasksFormData.twitter}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">LinkedIn</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="linkedin"
                value={TasksFormData.linkedin}
                onChange={handleInput}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show captasks  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {captasks &&
              captasks.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((tasks) => (
                <div key={tasks.id} className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${tasks.id}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${tasks.id}`}
                    >
                      {tasks.name}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${tasks.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {Object.keys(tasks).map((key, index) => {
                        if (key === "image") {
                          return "";
                        }
                        return (
                          <div className="d-flex" key={index}>
                            <span className="mx-2">{key} :</span>
                            {tasks[key].toString()}
                          </div>
                        );
                      })}

                      <div className="d-flex">
                        <span className="mx-2">Image :</span>
                        <a
                          href={tasks.image}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          View Image
                        </a>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => edit_captasks(tasks)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-sm btn-danger mx-2"
                          onClick={() => delete_captasks(tasks.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapTasks;
