import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Sponsors from "./components/Sponsors";
import Initiatives from "./components/Initiatives";
import Teams from "./components/Teams";
import UploadImage from "./components/UploadImage";
import Login from "./components/Login";
import Newsletter from "./components/NewsLetter";
import API from "./Services/axios";
import Gallery from "./components/Gallery";
import Events from "./components/Events";
import Timeline from "./components/Timeline";
import EventsUser from "./components/Events_User";
import PatandAcc from "./components/PatandAcc";
import CFA from "./components/CFA";
import AdminSignup from "./components/AdminSignup";
import CAP from "./components/Cap";
import Mailer from "./components/Mailer";
import Generator from "./components/Generator";
import CAPUser from "./components/CAP/CAPUsers";
import BlogEditor from "./components/BlogEditor";
import Blogs from "./components/Blogs";
import QrReader from "./components/QrReader";

import logout from "./components/Navbar";

import CAPUpdate from "./components/CAP/CAPupdate";

import TestEvents from "./components/TestEvents";
import Cookies from "js-cookie";
import MassMailerTemplate from "./components/MassMailerTemplate";
import SESTemplates from "./components/SesTemplates";
import MassMailerForm from "./components/SESTry";
function App() {
  const [accessToken, setAccessToken] = useState(
    Cookies.get("access_token") || null
  );
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(Cookies.get("role") || null);
  useEffect(() => {
    setRole(Cookies.get("role") || null);
  }, []);
  const getAuthUser = async () => {
    try {
      const user = (await API.get("/getAuthenticatedUser")).data;
      setUser(user);
      console.log(user);
    } catch (err) {
      console.log(err);
      Cookies.remove("access_token");
      Cookies.remove("role");
      // window.location.replace("/login");
    }
  };
  useEffect(() => {
    getAuthUser();
    setAccessToken(Cookies.get("access_token") || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(role);
  console.log(Cookies.get());
  console.log(role != "aaruush-csi-heads" || role != "aaruush-envision-heads");
  if (
    role != "aaruush-csi-heads" &&
    role != "aaruush-envision-heads" &&
    role != "aaruush-csi"
  ) {
    return (
      <div className="App">
        <Router>
          <Navbar accessToken={accessToken} logout={logout} />

          <Switch>
            <Route exact path="/">
              {accessToken ? (
                <Route>
                  <h1>Welcome to ADMIN PANEL </h1>
                  <UploadImage accessToken={accessToken} />
                </Route>
              ) : (
                <h1>Login to access ADMIN PANEL</h1>
              )}
            </Route>

            <Route exact path="/sponsors">
              {accessToken ? (
                <Sponsors accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/create-template">
              {accessToken ? (
                <MassMailerTemplate accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/view-template">
              {accessToken ? (
                <SESTemplates accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/send-template">
              {accessToken ? (
                <MassMailerForm accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/initiatives">
              {accessToken ? (
                <Initiatives accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/testevents">
              {accessToken ? (
                <TestEvents accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/capupdate">
              {accessToken ? (
                <CAPUpdate accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/teams">
              {accessToken ? (
                <Teams accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/gallery">
              {accessToken ? (
                <Gallery accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/events">
              {accessToken ? (
                <Events accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/eventsuser">
              {accessToken ? (
                <EventsUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/blogs">
              {accessToken ? (
                <Blogs accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/blogeditor">
              {accessToken ? (
                <BlogEditor accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/cfa">
              {accessToken ? (
                <CFA accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/attendance">
              {accessToken ? (
                <QrReader accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/timeline">
              {accessToken ? (
                <Timeline accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/patandacc">
              {accessToken ? (
                <PatandAcc accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/Generator">
              {accessToken ? (
                <Generator accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/massMailer">
              {accessToken ? (
                <Mailer accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/cap">
              {accessToken ? (
                <CAP accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/capuser">
              {accessToken ? (
                <CAPUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/newsletter">
              {accessToken ? (
                <Newsletter accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/adminsignup">
              {accessToken ? (
                <AdminSignup accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/login">
              {accessToken ? <Redirect to="/" /> : <Login />}
            </Route>
          </Switch>
          {/* {accessToken && <UploadImage accessToken={accessToken} />} */}
        </Router>
      </div>
    );
  } else if (role == "aaruush-csi") {
    return (
      <div className="App">
        <Router>
          <Navbar accessToken={accessToken} logout={logout} />
          <Switch>
            <Route exact path="/">
              {accessToken ? (
                <Route>
                  <h1>Welcome to ADMIN PANEL </h1>
                  <UploadImage accessToken={accessToken} />
                </Route>
              ) : (
                <h1>Login to access ADMIN PANEL</h1>
              )}
            </Route>

            <Route exact path="/eventsuser">
              {accessToken ? (
                <EventsUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/capupdate">
              {accessToken ? (
                <CAPUpdate accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/cfa">
              {accessToken ? (
                <CFA accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/attendance">
              {accessToken ? (
                <QrReader accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/Generator">
              {accessToken ? (
                <Generator accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/massMailer">
              {accessToken ? (
                <Mailer accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/cap">
              {accessToken ? (
                <CAP accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/capuser">
              {accessToken ? (
                <CAPUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/login">
              {accessToken ? <Redirect to="/" /> : <Login />}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  } else if (role == "aaruush-csi-heads") {
    return (
      <div className="App">
        <Navbar accessToken={accessToken} logout={logout} />
        <Router>
          <Switch>
            <Route path="/" exact>
              {accessToken ? (
                <QrReader accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/Generator">
              {accessToken ? (
                <Generator accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/massMailer">
              {accessToken ? (
                <Mailer accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route path="/login">
              {accessToken ? <Redirect to="/" /> : <Login />}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  } else if (role == "aaruush-envision-heads") {
    return (
      <div className="App">
        <Navbar accessToken={accessToken} logout={logout} />
        <Router>
          <Switch>
            <Route exact path="/">
              {accessToken ? (
                <Route>
                  <h1>Welcome to ADMIN PANEL </h1>
                  <UploadImage accessToken={accessToken} />
                </Route>
              ) : (
                <h1>Login to access ADMIN PANEL</h1>
              )}
            </Route>

            <Route exact path="/eventsuser">
              {accessToken ? (
                <EventsUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/capupdate">
              {accessToken ? (
                <CAPUpdate accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>

            <Route exact path="/cfa">
              {accessToken ? (
                <CFA accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/attendance">
              {accessToken ? (
                <QrReader accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/Generator">
              {accessToken ? (
                <Generator accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/massMailer">
              {accessToken ? (
                <Mailer accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/cap">
              {accessToken ? (
                <CAP accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/capuser">
              {accessToken ? (
                <CAPUser accessToken={accessToken} />
              ) : (
                <Redirect to="/login" />
              )}
            </Route>
            <Route exact path="/login">
              {accessToken ? <Redirect to="/" /> : <Login />}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
