import React, { useState, useEffect } from "react";
import API from "../Services/axios";
import axios from "axios";
const UploadImage = () => {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [folder, setFolderName] = useState("");
  const [url, setUrl] = useState("");
  const [deleteImage, setDeleteImage] = useState("");
  const [images, setImages] = useState([]);
  // console.log(name,folder,url);

  const onDeleteImage = async (e, data) => {
    e.preventDefault();
    const imagename = deleteImage.split("/")[4];
    const imagefolder = deleteImage.split("/")[3];
    console.log(imagename, imagefolder);
    const res = (
      await axios.delete(
        `https://7fj6her1nc.execute-api.ap-south-1.amazonaws.com/dev/uploadImage/${imagefolder}/${imagename}`
      )
    ).data;
    console.log(res);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      setImage(e.target.result);
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(name);
    console.log(folder);
    console.log(image);
    const url = (
      await axios.post(
        "https://7fj6her1nc.execute-api.ap-south-1.amazonaws.com/dev/uploadImage",
        {
          name: name
            .toLowerCase()
            .split(" ")
            .join("-"),
          folder,
          image,
        }
      )
    ).data.url;
    setUrl(url);
    if (url) {
      const data = (
        await API.post("/static/images", {
          name: name
            .toLowerCase()
            .split(" ")
            .join("-"),
          folder,
          url,
        })
      ).data;
      console.log(data);
    }
  };

  useEffect(() => {
    async function getImages() {
      const data = (await API.get("/static/images")).data;
      setImages(data);
    }
    getImages();
  }, []);

  async function delete_img(data) {
    const deleteImg = (await API.delete(`/static/images/${data.id}`)).data;
    console.log(deleteImg);
    const imagename = data.url.split("/")[4];
    const imagefolder = data.url.split("/")[3];
    console.log(imagename, imagefolder, data.url);
    if (data && data.url) {
      const deleteImgS3 = (
        await axios.delete(
          `https://7fj6her1nc.execute-api.ap-south-1.amazonaws.com/dev/deleteImage/${imagefolder}/${imagename}`
        )
      ).data;
      console.log(deleteImgS3);
    } else {
      console.log("no data url");
    }
  }
  return (
    <>
      <div className="container col-lg-6 mx-auto">
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Folder Name</label>
          <input
            type="text"
            value={folder}
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Image</label>
          <input type="file" onChange={handleImage} className="form-control" />
        </div>
        <span
          className="btn cursor-pointer bg-primary text-white"
          onClick={onSubmit}
        >
          Get URL
        </span>
        <div className="d-flex my-2 fs-5">
          <span className="mx-2 text-danger">Url:-</span>
          <a
            href={url && url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-justify text-wrap"
          >
            {url && url}
          </a>
        </div>
        <div>
          <div className="mb-3">
            <label className="form-label">Delete Image</label>
            <input
              type="type"
              onChange={(e) => {
                setDeleteImage(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <span
            className="btn cursor-pointer bg-primary text-white"
            onClick={onDeleteImage}
          >
            Delete Image
          </span>
        </div>
      </div>

      <div>
        <div className="col-lg-6 mx-auto border border-dark mt-5 rounded">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="fw-bold mx-auto">All Images</div>
            {images &&
              images
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((img) => (
                  <div key={img.id} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${img.id}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${img.id}`}
                      >
                        {img.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${img.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(img).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {img[key].toString()}
                            </div>
                          );
                        })}
                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={img.url}
                            alt={"img"}
                            className="mx-2"
                          >
                            View Image
                          </a>
                        </div>

                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_img(img)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
