import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import HtmlSaver from "../Services/htmlSaver";
import { encodeHtmlToBase64 } from "../utils";

const templateList = [
  'cap24WelcomeMail',
  'a24EventRegister',
  'mail',
  'template',
  'templated',
  'tsummitregdComplete',
  'tsummitwelcome',
  'certificatesA24',
  'teachersdaymain',
  'teacherday'
]

export default function Generator() {
  const emailEditorRef = useRef(null);
  const [template, LoadTemplate] = useState({});
  const [templateName, setTemplateName] = useState("");

  const handleDownload = (jsonData) => {
    if(!jsonData){
      window.alert("No data to download");
      return;
    }

    const jsonStr = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.json'; // Filename to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((data) => {
      console.log(data);
      LoadTemplate(data);
      window.alert("Data added to console");
      handleDownload(data);
    });
  };
  const handleLoadDesign = () => {
    // if (template.design === undefined) {
    //   window.alert("Goli beta masti nahi");
    // } else {
    if(!templateName){
      window.alert("Select a template");
      return;
    }
    const template = require(`./Assets/${templateName}.json`);
    console.log(template);
    emailEditorRef.current.editor.loadDesign(template);
    // }
  };
  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //   console.log("exportHtml", html);
      document.querySelector("#generated").innerText = html;
      setHtmlData(html);
    });
  };
  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  const [htmlData, setHtmlData] = useState("");

  const encodedHtml = () => {
    const html = document.querySelector("#generated").innerText;
    const encoded = encodeHtmlToBase64(html);
    console.log(encoded);
    navigator.clipboard.writeText(encoded);
    window.alert("Copied Encoded HTML to clipboard");
    return encoded;
  }
  
  useEffect(()=>{
    const html = document.querySelector("#generated").innerText;
    setHtmlData(html);
  }, [htmlData])

  const copyHtml = () => {
    const html = document.querySelector("#generated").innerText;
    navigator.clipboard.writeText(html);
    window.alert("Copied to clipboard");
  };
  return (
    <div className="p-3">
      <div className="h2 mx-auto">HTML GENERATOR</div>

      <div className="gap-x-2 flex">
        <button onClick={exportHtml} className="btn btn-primary mx-auto mx-2">
          Export HTML
        </button>
        <button onClick={saveDesign} className="btn btn-primary mx-auto">
          Save Design
        </button>
        <select
          name="template"
          id="template"
          className="form-select mx-auto w-50"
          onChange={(e) => setTemplateName(e.target.value)}
          >
          <option value="">Select Template</option>
          {templateList.map((template) => (
            <option key={template} value={template}>
              {template}
            </option>
          ))}
          </select>
        <button
          onClick={handleLoadDesign}
          className="btn btn-primary mx-auto mx-2"
        >
          Load Design
        </button>

        {
          htmlData&& (
            <>
            <HtmlSaver htmlContent={htmlData} />
            <button className="btn btn-success mx-auto" onClick={encodedHtml}>Export Encoded HTML</button>
            </>
          )
        }
      </div>

      <EmailEditor ref={emailEditorRef} onReady={onReady} />

      <div>
        <h4>Generated HTML</h4>
        <button onClick={copyHtml} className="btn btn-primary mx-auto">
          Copy Exported HTML
        </button>
        <div id="generated" className="card"></div>
      </div>
    </div>
  );
}
