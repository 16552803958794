import React, { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { useState } from "react";
import { render } from "react-dom";
// import { COUNTRIES } from "./countries";
import "./blog.css";
import { WithContext as ReactTags } from "react-tag-input";

import API from "../Services/axios";

const BlogEditor = ({ blogEditData }) => {
  const [editorState, setEditorState] = React.useState();
  const [blogData, setBlogData] = React.useState({ ...blogEditData });

  console.log(blogData);
  //   const suggestions = COUNTRIES.map((country) => {
  //     return {
  //       id: country,
  //       text: country,
  //     };
  //   });

  useEffect(() => {
    setBlogData({ ...blogEditData });
    console.log(convertFromRaw(blogEditData.body));
    // setEditorState(convertFromRaw(blogEditData.body));
    const NewEditorState = EditorState.createWithContent(
      convertFromRaw(blogEditData.body)
    );
    setEditorState(NewEditorState);
    setTags(blogEditData.tags);
  }, [blogEditData]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
    TAB: 9,
    SPACE: 32,
  };

  const [tags, setTags] = React.useState([]);

  const delimiters = [
    KeyCodes.comma,
    KeyCodes.enter,
    KeyCodes.TAB,
    KeyCodes.SPACE,
  ];
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };
  //   {
  /* 
  const App = () => {
    
    
  
    const handleTagClick = index => {
      console.log('The tag at index ' + index + ' was clicked');
    }; */
  //   }
  console.log(tags);
  const handleSubmit = (e) => {
    e.preventDefault();
    setBlogData({ ...blogData, tags: tags, date: new Date() });
    console.log(blogData);
    API.put("/blog/" + blogData.id, blogData).then((res) => {
      console.log(res);
      if (res.status == 200) {
        window.alert("Blog Updated Successfully");
      } else {
        window.alert("Blog Update Failed");
      }
      // window.alert
    });
  };

  return (
    <div className="container">
      {/* title, author, tags */}
      <h1 className="center">Add a new Blog post</h1>
      <form className="">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="Title">
              TITLE
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Title..."
            required
            value={blogData.title}
            onChange={(e) => {
              setBlogData({ ...blogData, title: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="Title">
              KEY (URL)
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Key..."
            required
            value={blogData.key}
            onChange={(e) => {
              setBlogData({ ...blogData, key: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="Title">
              Header Image (URL)
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Key..."
            required
            value={blogData.headerImage}
            onChange={(e) => {
              setBlogData({ ...blogData, headerImage: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="Author">
              AUTHOR
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Author..."
            required
            value={blogData.author}
            onChange={(e) => {
              setBlogData({ ...blogData, author: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3 grp">
          <div className="input-group-prepend">
            <span className="input-group-text" id="Tags">
              TAGS
            </span>
          </div>

          {/* {String(tags)} */}
          <ReactTags
            tags={tags}
            classNames={"w-100"}
            //   suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            //   handleTagClick={handleTagClick}
            inputFieldPosition="top"
            autocomplete
            placeholder="Tags..."
          />
          {/* </div> */}

          {/* render(<App />, document.getElementById('root')); */}
        </div>
      </form>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName=""
        wrapperStyle={{
          border: "1px solid black",
          minHeight: "200px",
          padding: "10px",
          borderRadius: "5px",
          margin: "10px",
        }}
        editorStyle={{
          border: "1px solid black",
          minHeight: "200px",
          padding: "10px",
          borderRadius: "5px",
          padding: "10px",
        }}
        onEditorStateChange={(e) => {
          setEditorState(e);
          setBlogData({
            ...blogData,
            raw: e.getCurrentContent().getPlainText(),
            body: convertToRaw(e.getCurrentContent()),
          });
          // console.log(convertToRaw(e.getCurrentContent()));
          // console.log(e.getCurrentContent().getPlainText());
        }}
      />

      <button type="button" class="btn btn-primary" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default BlogEditor;
