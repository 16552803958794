import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ accessToken }) => {
  const logout = () => {
    Cookies.remove("access_token");
    Cookies.remove("role");
    window.location.reload();
  };

  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    setRole(Cookies.get("role"));
  }, []);

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src="https://aaruush22-bucket.s3.ap-south-1.amazonaws.com/main/aaruush.png"
              alt=""
              width="80"
              className="d-inline-block align-text-middle"
            />
            Admins Panel
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {accessToken ? (
                <>
                  {role === "aaruush-admin" ? (
                    <>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Main Website
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/sponsors"
                            >
                              Sponsors
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/initiatives"
                            >
                              Initiatives
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/teams"
                            >
                              Teams
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/gallery"
                            >
                              Gallery
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/events"
                            >
                              Events
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/eventsuser"
                            >
                              Events User
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/newsletter"
                            >
                              NewsLetter
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/timeline"
                            >
                              Timeline
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/patandacc"
                            >
                              Pat and Acc
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/blogs"
                            >
                              Blogs
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/blogeditor"
                            >
                              Blog Editor
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to="/testevents"
                            >
                              Test Events
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cfa"
                        >
                          CFA
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/attendance"
                        >
                          QR
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cap"
                        >
                          CAP
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capuser"
                        >
                          CAPUser
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capupdate"
                        >
                          CAP Update
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/generator"
                        >
                          Generator
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/massMailer"
                        >
                          Mass Mailer
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Template Control
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/create-template"
                        >
                          Template Post
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/view-template"
                        >
                          View Template
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/send-template"
                        >
                          Send Template
                        </Link>
                      </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/adminsignup"
                        >
                          Create User
                        </Link>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-danger nav-link"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </li>
                    </>
                  ) : role === "aaruush-csi" ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cap"
                        >
                          CAP
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capuser"
                        >
                          CAPUser
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/eventsuser"
                        >
                          Events User
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/attendance"
                        >
                          QR
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/massMailer"
                        >
                          Mass Mailer
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/generator"
                        >
                          Generator
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cfa"
                        >
                          CFA
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capupdate"
                        >
                          CAP Update
                        </Link>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-danger nav-link"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </li>
                    </>
                  ) : role === "aaruush-csi-heads" ? (
                    <>
                      {/* <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/attendance"
                        >
                          QR
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/massMailer"
                        >
                          Mass Mailer
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/generator"
                        >
                          Generator
                        </Link>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-danger nav-link"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </li>
                    </>
                  ) : role === "aaruush-envision-heads" ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cap"
                        >
                          CAP
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capuser"
                        >
                          CAPUser
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/eventsuser"
                        >
                          Events User
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/attendance"
                        >
                          QR
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/massMailer"
                        >
                          Mass Mailer
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/generator"
                        >
                          Generator
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/cfa"
                        >
                          CFA
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/capupdate"
                        >
                          CAP Update
                        </Link>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-danger nav-link"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </li>
                    </>
                  ) : null}
                </>
              ) : (
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
