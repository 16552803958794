import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Sponsors = ({ accessToken }) => {
  const [formData, setFormData] = useState({});
  const [categoryformData, setCategoryFormData] = useState({});
  const [sponsors, setSponsors] = useState([]);
  const [sponsorscategory, setSponsorsCategory] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [category, setCategory] = useState();

  console.log(sponsorscategory);

  useEffect(() => {
    async function getSponsors() {
      const data = (await API.get("/static/sponsors")).data;

      setSponsors(data);
    }
    getSponsors();
  }, [change]);

  useEffect(() => {
    async function getSponsorsCategory() {
      const data = (await API.get("/static/SponsorsCategory")).data;
      setSponsorsCategory(data);
    }
    getSponsorsCategory();
  }, [change]);

  // console.log(sponsorscategory);
  // edit sponsors
  const edit_sponsors = async (sponsor) => {
    setEdit(true);
    setFormData(sponsor);
  };
  const update_sponsor = async () => {
    if (formData) {
      const message = (
        await API.put(`/static/sponsors/${formData.id}`, {
          ...formData,
          category,
        })
      ).data.message;
      setEdit(false);
      setFormData({});
      setChange(!change);
      return message;
    } else {
      alert("Please Fill all required fields");
    }
  };

  // delete sponsors
  const delete_sponsors = async (id) => {
    const message = (await API.delete(`/static/sponsors/${id}`)).data.message;
    alert(message);
    setChange(!change);
  };
  // delete sponsors category
  const delete_sponsorscategory = async (id) => {
    const message = (await API.delete(`/static/SponsorsCategory/${id}`)).data
      .message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCategoryInput = (e) => {
    setCategoryFormData({
      ...categoryformData,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async (e) => {
    if (formData) {
      e.preventDefault();
      const message = edit
        ? update_sponsor()
        : (
            await API.post("/static/sponsors", {
              ...formData,
              category,
            })
          ).data.message;
      alert(message);
      setFormData({});
      setChange(!change);
    } else {
      alert("Please Fill all required fields");
    }
  };

  // console.log(categoryformData);
  // console.log(category);

  const onCategorySubmit = async (e) => {
    if (categoryformData) {
      e.preventDefault();
      const message = (
        await API.post("/static/SponsorsCategory", {
          ...categoryformData,
        })
      ).data.message;
      alert(message);
      setCategoryFormData({});
      setChange(!change);
    } else {
      alert("Please Fill all required  category fields ");
    }
  };

  return (
    <div className="container">
      <div className="row">
        {/* sponsor form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="fw-bold mx-auto">Add Sponsors</div>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData && formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Type</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData && formData.type}
                name="type"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Website Link</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData && formData.link}
                name="link"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Linkedin Link</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData && formData.linkedin}
                name="linkedin"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Category</label>
              {/* <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="category"
                value={formData.category}
                onChange={handleInput}
              /> */}
              <select
                className="form-select"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option disabled selected>
                  Select Category
                </option>
                {sponsorscategory &&
                  sponsorscategory
                    .sort(function(a, b) {
                      return a.timestamp - b.timestamp;
                    })
                    .reverse()
                    .map((category, index) => {
                      return (
                        <option
                          key={index}
                          value={category.id}
                          name="category"
                          className="form-control"
                        >
                          {category.name}
                        </option>
                      );
                    })}
              </select>
            </div>
            {/* <div className="mb-3">
              <label className="form-label">Type</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="type"
                value={formData && formData.type}
                onChange={handleInput}
              />
            </div> */}
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={formData && formData.image}
                onChange={handleInput}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>

        <div className="container col-lg-5">
          <div>
            <div className="fw-bold mx-auto">Add Sponsors Category</div>
            <div className="mb-3">
              <label className="form-label">Category Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={categoryformData && categoryformData.name}
                name="name"
                onChange={handleCategoryInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={categoryformData && categoryformData.image}
                onChange={handleCategoryInput}
              />
            </div>
            <div onClick={onCategorySubmit} className="btn btn-primary">
              Submit
            </div>
          </div>
        </div>

        {/* show sponsors  */}
        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="fw-bold mx-auto">All Sponsors</div>
            {sponsors &&
              sponsors
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((sponsor, idx) => (
                  <div key={sponsor.id} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${idx}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${idx}`}
                      >
                        {sponsor.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${idx}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(sponsor).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {sponsor[key].toString()}
                            </div>
                          );
                        })}
                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={sponsor.image}
                            alt={"sponsor"}
                            className="mx-2"
                          >
                            View Image
                          </a>
                        </div>

                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_sponsors(sponsor)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_sponsors(sponsor.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        {/* show sponsors  */}
        <div className="col-lg-6">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="fw-bold mx-auto">Sponsors Category</div>
            {sponsorscategory &&
              sponsorscategory
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((sponsor) => (
                  <div key={sponsor.id} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${sponsor.id}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${sponsor.id}`}
                      >
                        {sponsor.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${sponsor.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(sponsor).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {sponsor[key].toString()}
                            </div>
                          );
                        })}
                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={sponsor.image}
                            alt={"sponsor"}
                            className="mx-2"
                          >
                            View Image
                          </a>
                        </div>

                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_sponsorscategory(sponsor.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
