import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { read, utils } from "xlsx";
import Papa from "papaparse";
const MassMailer = () => {
  // const [addemail, setaddemail] = useState("")

  const [extraemails, setExtraEmails] = useState([]);

  const [formdata, setFormData] = useState({ html: "" });
  const [passWord, setPassWord] = useState("");

  // const [parsedData, setParsedData] = useState([]);
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);
  //State to store the values
  const [values, setValues] = useState([]);
  //   const [html, setHtml] = useState("");

  useEffect(() => {
    document.querySelector("#preview").innerHTML = formdata.html;
  }, [formdata]);
  //   document.querySelector("#preview").innerHTML = formdata.html;
  const mergeAll = (arr) => {
    let merged = [].concat.apply([], arr);
    return merged;
  };

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        // setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);

        setExtraEmails([...extraemails, ...mergeAll(valuesArray)]);
      },
    });
  };

  // function readAppendFile(f) {
  // var name = f.name;
  // const reader = new FileReader();
  // reader.onload = (evt) => {
  //     /* Parse data */
  //     const bstr = evt.target.result;
  //     const wb = read(bstr, { type: "binary" });
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     const data = utils.sheet_to_csv(ws, { header: 1 });
  //     setExtraEmails([...extraemails, ...convertToJson(data)]); // shows data in json format
  // };
  // reader.readAsBinaryString(f);
  // }

  // function convertToJson(csv) {
  //     var lines = csv.split("\n");
  //     var result = [];
  //     for (var i = 1; i < lines.length; i++) {
  //         result.push(lines[i]);
  //     }
  //     return result;
  // }

  // function arrayRemove(arr, value) {
  //     if (arr.length === 1) {
  //         return "empty";
  //     } else {
  //         return arr.filter(function (ele) {
  //             return ele !== value;
  //         });
  //     }
  // }

  const HandleSubmit = async () => {
    if (passWord === "MassMail Khulja Sim Sim") {
      try {
        const data = await API.post("/massMailer", {
          subject: formdata.subject,
          html: formdata.html,
          emails: extraemails,
        });
        console.log(data);
        if (data.data.status === 200) {
          alert("Mail Sent Successfully");
          // toast.success(`MassMailer sent successfully!`, {
          //     position: "top-center",
          //     autoClose: 3000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          // });
          setFormData({});
          setExtraEmails([]);
        } else {
          alert("MassMailer sent error");
          // toast.error("Error: MassMailer Error", {
          //     position: "top-center",
          //     autoClose: 3000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          // });
        }
      } catch {
        alert("MassMailer sent error");
        // toast.error("Error: MassMailer Error", {
        //     position: "top-center",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        // });
      }
    } else {
      alert("Goli beta masti nai");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    HandleSubmit();
  };

  // const getobjects = (obj) => {
  //     const newobj = obj?.map((data) => { return ({ label: data, value: data }) });
  //     return newobj
  // }

  // const getstring = (obj) => {
  //     const array = obj?.map((data) => { return data.value })
  //     return array
  // }
  return (
    <div className="container">
      {/* <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
      <div className="row">
        {/* event form  */}
        <div className="container col-lg-7">
          <form onSubmit={onSubmit}>
            <div className="fw-bold mx-auto">Mass Mailer</div>
            <div className="mb-3">
              <label className="form-label">Subject</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formdata.subject ? formdata.subject : ""}
                name="subject"
                placeholder="Text here"
                onChange={(e) => {
                  setFormData({ ...formdata, subject: e.target.value });
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">HTML here</label>
              <textarea
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="html"
                style={{
                  color: "#818181",
                  background: "#F6F5F6",
                  border: "2px solid grey",
                  borderRadius: "8px",
                }}
                rows="20"
                cols="20"
                value={formdata.html ? formdata.html : ""}
                placeholder="HTML here"
                onChange={(e) => {
                  setFormData({ ...formdata, html: e.target.value });
                }}
              />
            </div>

            {/* 
                        <div className="mb-3">
                            <h2 className="form-label">Add Email to Mass Email List</h2>
                            <input
                                type="email"
                                className=""
                                style={{
                                    color: "#818181",
                                    background: "#F6F5F6",
                                    border: "2px solid grey",
                                    borderRadius: "8px",
                                }}
                                placeholder="Email"
                                name="email"
                                value={addemail}
                                onChange={(e) => {
                                    setaddemail(e.target.value);
                                }}
                            />
                            <br />
                            <div
                                className="btn btn-success"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (addemail) {
                                        setExtraEmails([...extraemails, addemail]);
                                        setaddemail("")
                                    }
                                }}
                            >
                                Submit
                            </div>
                        </div> */}
            {/* 
                        <div className="mb-3">

                            <div className=" mt-4 d-flex flex-column justify-content-center">
                                Upload file for email list<br></br>
                                <input
                                    type="file"
                                    // className="mt-8 block w-full text-sm text-slate-500 place-content-center ml-[35%]
                                    //             file:mr-4 file:py-2 file:px-4
                                    //             file:rounded-full file:border-0
                                    //             file:text-sm file:font-semibold
                                    //             file:bg-blue-50 file:text-blue-700
                                    //             hover:file:bg-violet-100"
                                    onChange={(e) => {
                                        readAppendFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                            <hr className="mt-5"></hr>
                            <table className="mt-5 ">
                                <thead>
                                    <tr
                                        className="text-md font-semibold   text-gray-900   border-[#B9B9B9]  text-center border-3"
                                        style={{ background: "rgba(0, 0, 0, 0.05)" }}
                                    >
                                        <th className="py-3  border-[#B9B9B9] border-2 bg-gradient-to-r from-gray-100 to-gray-200">Email</th>
                                        <th className="py-3 border-[#B9B9B9] border-2 bg-gradient-to-r from-gray-100 to-gray-200">Modify</th>
                                    </tr>
                                </thead>
                                <tbody
                                    className=" max-h-[40vh] overflow-scroll"
                                    style={{ maxHeight: "30vh", overflowY: "scroll" }}
                                >
                                    {extraemails &&
                                        extraemails.reverse().map((email, idx) => {
                                            return (
                                                <tr className="text-[#000000] odd:bg-white even:bg-slate-100">

                                                    <td className="text-center py-3 text-md  border-[#B9B9B9] border-2">
                                                        <div>
                                                            <p>{email}</p>
                                                        </div>
                                                    </td>

                                                    <td className="text-center py-3 text-md border-[#B9B9B9] border-2 ">
                                                        <div>
                                                            <p
                                                                className="text-[#874439] font-bold cursor-pointer hover:underline transition"
                                                                onClick={() => {
                                                                    const resp = arrayRemove(extraemails, email);
                                                                    if (resp !== "empty") {
                                                                        setExtraEmails(resp);
                                                                    } else {
                                                                        setExtraEmails([]);
                                                                    }
                                                                }}
                                                            >
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div> */}
            <div className="mb-3">
              <div className=" mt-4 d-flex flex-column justify-content-center">
                Upload file for email list<br></br>
                <input
                  type="file"
                  name="file"
                  onChange={changeHandler}
                  accept=".csv"
                  style={{ display: "block", margin: "10px auto" }}
                />
              </div>
              <hr className="mt-5"></hr>
              <table>
                <thead>
                  <tr>
                    {tableRows.map((rows, index) => {
                      return <th key={index}>{rows}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {values.map((value, index) => {
                    return (
                      <tr key={index}>
                        {value.map((val, i) => {
                          return <td key={i}>{val}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mb-3">
              <div>
                <h3>Preview</h3>
                <div id="preview" className="card"></div>
              </div>

              <hr></hr>
            </div>
            <div className="mb-3">
              <label className="form-label">Password to Mass Mail</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={passWord}
                name="password"
                placeholder="Yeh Password Daalo tabhi aage badh paoge"
                onChange={(e) => {
                  setPassWord(e.target.value);
                }}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MassMailer;
