import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../Services/axios";

const Initiatives = ({ accessToken }) => {
  const [formData, setFormData] = useState({});
  const [initiatives, setInitiatives] = useState([]);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    async function getInitiatives() {
      const data = (await API.get("/static/initiatives")).data;

      setInitiatives(data);
    }
    getInitiatives();
  }, [change]);

  // edit initiatives
  const edit_initiatives = async (initiative) => {
    setEdit(true);
    setFormData(initiative);
  };
  const update_initiatives = async () => {
    const message = (
      await API.put(`/static/initiatives/${formData.id}`, formData)
    ).data.message;
    setEdit(false);
    setFormData({});
    setChange(!change);
    return message;
  };

  // delete initiatives
  const delete_initiatives = async (id) => {
    const message = (await API.delete(`/static/initiatives/${id}`)).data
      .message;
    alert(message);
    setChange(!change);
  };

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const message = edit
      ? update_initiatives()
      : (
          await API.post("/static/initiatives", {
            ...formData,
          })
        ).data.message;
    alert(message);
    setChange(!change);
  };

  return (
    <div className="container">
      <div className="row">
        {/* initiative form  */}
        <div className="container col-lg-5">
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label className="form-label">Si</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.si}
                name="si"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={formData.name}
                name="name"
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="description"
                value={formData.description}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="image"
                value={formData.image}
                onChange={handleInput}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Thumbnail Image URL</label>
              <input
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                name="thumbnail"
                value={formData.thumbnail}
                onChange={handleInput}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        {/* show initiatives  */}
        <div className="col-lg-7">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {initiatives &&
              initiatives
                .sort(function(a, b) {
                  return a.timestamp - b.timestamp;
                })
                .reverse()
                .map((initiative) => (
                  <div key={initiative.id} className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${initiative.id}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${initiative.id}`}
                      >
                        {initiative.name}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${initiative.id}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {Object.keys(initiative).map((key, index) => {
                          if (key === "image") {
                            return "";
                          }
                          return (
                            <div className="d-flex" key={index}>
                              <span className="mx-2">{key} :</span>
                              {initiative[key].toString()}
                            </div>
                          );
                        })}

                        <div className="d-flex">
                          <span className="mx-2">Image :</span>
                          <a
                            href={initiative.image}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {" "}
                            View Image
                          </a>
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => edit_initiatives(initiative)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-sm btn-danger mx-2"
                            onClick={() => delete_initiatives(initiative.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Initiatives;
