import React, { useState, useEffect } from "react";
// import UploadImage from "./UploadImage";
import API from "../../Services/axios";
import CsvDownload from 'react-json-to-csv'

const Events_User = () => {
    const [users, setUsers] = useState([]);
    const [count, setCount] = useState(0);
    // const [email, setEmail] = useState();
    // const [singleuser, setSingleUser] = useState();

    const getUsers = async () => {
        const data = (await API.get(`/users/cap`)).data;
        console.log(data)
        setUsers(data);
        setCount(data.length)
    };

    // const getSingleUser = async (e) => {
    //     if (email) {
    //         e.preventDefault();
    //         const data = (
    //             await API.get(`/users/${email}`)
    //         ).data;
    //         setSingleUser(data);
    //     } else {
    //         alert("Please Fill all required fields");
    //     }
    // };

    return (
        <>

            <div className="col-lg-7">
                <div
                    className="col-lg-5 card mt-1 mb-1 mx-auto p-1"
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    <div className="fw-bold mx-auto">Get Users ( Total users {count}  )
                        <button
                            className="btn btn-outline-primary"
                            type="button"
                            id="button-addon2"
                            onClick={getUsers}
                        >
                            View Users
                        </button>
                        {/* <button
                            className="btn btn-outline-primary"
                            type="button"
                            id="button-addon2"
                            onClick={getUsers}
                        >
                            Download Users
                        </button> */}
                        <CsvDownload data={users} />
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    {users &&
                        users.sort(function (a, b) { return a.timestamp - b.timestamp }).reverse().map((gallery, idx) => (
                            <div key={idx} className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#flush-collapse${idx}`}
                                        aria-expanded="false"
                                        aria-controls={`flush-collapse${idx}`}
                                    >
                                        {gallery.name}
                                    </button>
                                </h2>
                                <div
                                    id={`flush-collapse${idx}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        {Object.keys(gallery).map((key, index) => {
                                            if (key === "image") {
                                                return null; // Use null instead of an empty string to avoid rendering empty elements
                                            }
                                            return (
                                                <div className="d-flex" key={index}>
                                                    <span className="mx-2">{key} :</span>
                                                    {typeof gallery[key] === "object" && gallery[key] !== null ? (
                                                        <ul className="mx-2">
                                                            {Object.entries(gallery[key]).map(([subKey, subValue], subIndex) => (
                                                                <li key={subIndex}>
                                                                    {subKey}: {subValue && typeof subValue === "object" ? (
                                                                        <ul>
                                                                            {Object.entries(subValue).map(([innerKey, innerValue], innerIndex) => (
                                                                                <li key={innerIndex}>{innerKey}: {innerValue.toString()}</li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : (
                                                                        subValue && subValue.toString()
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <span className="mx-2">{gallery[key] && gallery[key].toString()}</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>

                                </div>
                            </div>
                        ))}
                </div>
            </div>



        </>
    );
};

export default Events_User;
